import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { FormBuilderService } from 'src/app/services/form-builder.service';
import { EventServicesService } from 'src/app/services/event-services.service';
import * as codes from '../../../assets/Json/collegeList.json';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { OtpService } from 'src/app/services/otp.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

export interface FormModel {
  fieldId?: string,
  fieldOrder: number,
  fieldName: string,
  fieldDescription: string,
  isMandatory: boolean,
  formName: string,
  displaySection: string,
  placeHolder: string,
  fieldType: string,
  answer: Array<any>,
  isValid?: boolean,
  schoolId?: string,
  dependant?: Dependancy,
  fieldDefaultValue?: Array<any>,
  fieldOptions?: Array<FieldOptions>,
  fileProperities?: FieldProperties,
  visible?: boolean,
  disabled?: boolean
}

export interface Dependancy {
  dependantOn: string,
  dependantValues: Array<string>
}

export interface FieldOptions {
  label: string,
  value: any
}

export interface FieldProperties {
  fileFormat: Array<string>,
  fileSize: any
}
@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.css'],
  providers: [MessageService]
})
export class RegistrationFormComponent implements OnInit {

  templateModels: Array<FormModel> = [];
  templateCollections: Array<Array<FormModel>> = [];
  invalidField: any;
  encryptKey = environment.ENCRYPTION.ENCRYPTION_KEY;

  pageNumber: number = 1;
  otpTimer: number = 0;

  clicked: boolean = false;
  isFormValid: boolean = false;
  submitBtnLoading = false;
  submitted: boolean = false;
  submittedSuccessfully: boolean = false;
  isLoading: boolean = true;
  emailExists: boolean = false;
  mobileExists: boolean = false;
  inValidLocation: boolean = false;
  formExpired: boolean = false;
  formExceeded: boolean = false;
  belowAge: boolean = false;
  existingFormSubmit: boolean = false;
  verifyLoading: boolean = false;
  enableVerify: boolean = false;
  duplicateData: any = {};

  countdown: string = '';
  formName: string = '';
  candidateId: string = '';
  otpValue: string = '';
  verifyChoice: string = '';
  eventId: any;
  eventTitle: any;
  sourceTag: any;
  userOtpData: any;
  // otpResponse: any;
  mobileOtp: any;
  intervalId: any;
  mobileNumber: any;
  countryCode: any;

  registrationStartDate: string = '';
  registrationEndDate: string = '';
  collegeList: any = (codes as any).default;

  schoolCode: string = '';
  companyLogo: string = '';
  inviteId: string = '';

  inEditMode: boolean = false;
  ansChange: any = null;
  jobUserMappingId: string = '';
  invalidUrl: boolean = false;
  offerFormExpired: boolean = false;

  offerFormSuccess: boolean = false;
  offerFormWarning: boolean = false;
  hideForm: boolean = false;
  otpRequired: boolean = false;
  otpVerified: boolean = false;
  verifyByEmail: boolean = false;
  emailToVerify: string = '';
  mobileToVerify: string = '';
  p1EmailVerified: boolean = false;
  p1MobileVerified: boolean = false;
  otpSent : boolean = false;
  waitToResend: boolean = false;
  loadResend: boolean = false;
  enableMobile: boolean = false;
  
  constructor(
      private formBuilderServices: FormBuilderService, 
      private messageServices: MessageService, 
      private activatedRoute: ActivatedRoute, 
      private eventService: EventServicesService, 
      private globalService: GlobalServicesService,
      private otpService: OtpService,
    ) {
  }

  ngOnInit(): void {
    this.globalService.getSchoolDetails().subscribe(res => {
      if(res.status == 200){
        this.enableMobile = res.data[0].otpSettings?.registrationForm;
      }
    })
    let encryptedEmail: string = '';
    this.activatedRoute.queryParamMap.subscribe((param) => {
      this.formName = param.get('form') || '';
      this.eventId = param.get('eventId') || null;
      this.eventTitle = param.get('eventTitle') || null;
      this.sourceTag = param.get('sourceTag') || null;
      this.jobUserMappingId = param.get('id') || '';
      this.inviteId = param.get('inviteId') || '';
      this.candidateId = param.get('candidateId') || '';
      encryptedEmail = param.get('email') || '';
      if(this.formName === 'Offer Acceptance and Joining Confirmation Form')
      {
        this.hideForm = true;
        this.inEditMode = true;
      }
    })
    this.globalService.schoolData.subscribe(() => {
      this.schoolCode = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_code
      : '';
      this.companyLogo = localStorage.getItem('schoolDetails')
      ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0]?.company?.companyLogo
      : '';
      if(this.formName == 'Extended Details and Documentation Form'){
        this.eventService.getJobUserMapping(this.eventId, this.candidateId).subscribe(res => {
          const documentDeadline = res.data?.documentDeadline;
          this.offerFormExpired = moment().utc().isAfter(documentDeadline);

          if(!this.offerFormExpired){
            this.otpRequired = true;
            this.otpVerified = false;
          } else {
            this.otpRequired = false;
            this.hideForm = true;
            this.isLoading = false;
          }
        })
      }
      if(this.formName == 'Registration Form Non-Technical' || this.formName == 'Registration Form' || this.formName == ' Registration Form'){
        this.verifyByEmail = true;
        if(encryptedEmail?.length){
          const encrypted =CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Hex.parse(encryptedEmail));
          const decrypted = CryptoJS.AES.decrypt(encrypted, this.encryptKey).toString(CryptoJS.enc.Utf8);
          this.otpRequired = true;
          this.emailToVerify = decrypted;
        }
      }
      if(this.formName != 'Extended Details and Documentation Form'){
        this.eventId ? this.validateDate() : this.getTemplates();
      }
    })
  }

  validateDate() {
    this.eventService.getEventById(this.eventId).subscribe((res) => {
      this.registrationStartDate = res.data?.registrationStartDate?.toString();
      this.registrationEndDate = res.data?.registrationEndDate?.toString();
      this.getTemplates();
    })

  }

  validateRegistration(): boolean {
    if (this.registrationStartDate && this.registrationStartDate.length && this.registrationEndDate && this.registrationEndDate.length) {
      return true;
    }
    else {
      return false;
    }
  }

  bindValuesInTemplate(data: any)
  {
    let templateModel: any = [];
    data.forEach((data: any) => {
      if (data.formDetails[0].formName == this.formName) {
        templateModel = data.formDetails;
      }
    });
    return templateModel;
  }

  getTemplates = () => {
    this.isLoading = true;
    let _templateModels: Array<any> = [];
    this.formBuilderServices.getTemplate().subscribe(res => {
      let currentDate = res.data.date?.toString();
      let data = res.data.formDetails;
      if (this.validateRegistration()) {
        if (currentDate >= this.registrationStartDate && currentDate <= this.registrationEndDate) {
          _templateModels = this.bindValuesInTemplate(data)
        }
        else {
          (this.formName == 'Registration Form' || this.formName == 'Registration Form Non-Technical') ? this.formExpired = true : _templateModels = this.bindValuesInTemplate(data);          
        }
      }
      else {
        _templateModels = this.bindValuesInTemplate(data)
      }

      _templateModels.forEach((eachField: any)=>{
        if(eachField.fieldName === 'Highest Degree - College Name')
        {
          eachField.fieldOptions = this.collegeList;
        }
      })

      this.templateModels = _templateModels.map((model: FormModel) => ({ ...model, answer: [], isValid: model.isMandatory ? false : true, visible: model.dependant ? false : true }));


      let sections: Array<string> = [];
      let _displaySection = '';

      if(this.formName === 'Offer Acceptance and Joining Confirmation Form')
      {
        this.otpService.getJobUserById(this.jobUserMappingId).subscribe((res:any) => {
          this.eventId = res.data?.jobId ? res.data?.jobId : res.data?.eventJobId; 
          const offerDeadline = res.data?.offerDeadline;
          this.offerFormExpired = moment().utc().isAfter(offerDeadline);

          if(!this.offerFormExpired){
            this.eventService.getUserDetailsByJobUserId(this.jobUserMappingId).subscribe((res: any)=>{
              this.hideForm = false;
              if(res.status === 200)
              {
                let userData: any = res?.data[0];
                let userDataObj: any = {
                  'Candidate ID' : userData.id,
                  'Name' : userData.name,
                  'Email' : userData.email,
                  'Mobile' : '+'+userData.countryCode+' '+userData.mobile_number
                }
                this.candidateId = userData.id,
                this.otpRequired = true;
                this.otpVerified = false;
                
                this.templateModels.forEach((eachModel: FormModel) => {
                  for(const data in userDataObj)
                  {
                    if (eachModel.fieldName === data) {
                      eachModel.answer = [userDataObj[data]];
                      this.ansChange = !this.ansChange;
                      eachModel.isValid = true;
                    }
                  }
                })
                  _templateModels.forEach((model: FormModel) => {
                    if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
                      _displaySection = model.displaySection;
                      sections.push(_displaySection);
                    }
                  })
                  sections.forEach((eachSection: string) => {
                    this.templateCollections.push(this.templateModels.filter((model: FormModel) => model.displaySection == eachSection));
                    if(this.inEditMode)
                    {
                      this.ansChange = !this.ansChange;
                    }
                  })
                  this.isLoading = false;
              }
              else
              {
                this.isLoading = false;
                this.offerFormWarning = true;
                let errorMessage = res?.error?.message
                if(errorMessage)
                {
                  if(errorMessage === 'Invalid Url')
                  {
                    this.invalidUrl = true;
                  }
                }
              }
            })
          } else {
            this.otpRequired = false;
            this.hideForm = true;
            this.isLoading = false;
          }
        })
      }
      else if(this.inviteId && (this.formName === 'Registration Form' || this.formName === 'Registration Form Non-Technical')){
        if(this.inviteId){
          this.inEditMode = true;
            this.formBuilderServices.getInviteData(this.inviteId).subscribe((res: any)=>{
    
            let inviteData = res?.data[0];
            let inviteDataObj: any = {
              'Name' : inviteData.firstName+' '+inviteData.lastName,
              'Primary Email Address' : inviteData.email,
              'Primary Mobile Number' : inviteData.mobileNumber,
              'How did you hear about us?' : inviteData.sourceType,
              'Please select the name of the training/assessment company that referred you' : inviteData.sourceName,
            }
    
            this.templateModels.forEach((eachModel: FormModel) => {
              for(const data in inviteDataObj)
              {
                if (eachModel.fieldName === data) {
                  if(data == 'Primary Mobile Number'){
                    eachModel.answer = ['91', inviteDataObj[data]]
                  } else {
                    eachModel.answer = [inviteDataObj[data]];
                  }
                  this.ansChange = !this.ansChange;
                  eachModel.isValid = true;
                  eachModel.disabled = eachModel.fieldName == 'Name' ? false : true;
                }
              }
            })
    
            _templateModels.forEach((model: FormModel) => {
              if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
                _displaySection = model.displaySection;
                sections.push(_displaySection);
              }
            })
            sections.forEach((eachSection: string) => {
              this.templateCollections.push(this.templateModels.filter((model: FormModel) => model.displaySection == eachSection));
              if(this.inEditMode)
              {
                this.ansChange = !this.ansChange;
              }
            })
            this.isLoading = false;
          })
          }
        } 
      else
      {
        if(this.formName === 'Extended Details and Documentation Form')
        {
          this.inEditMode = true;
          this.isLoading = true;
          this.isLoading = true;
          this.formBuilderServices.getCandidateFormDetails(this.candidateId, this.formName).subscribe((res: any)=>{
            let userDataObj = res.data;
            if(userDataObj.length === 0)
            {
              userDataObj.push({
                fieldName : 'Candidate ID',
                answer: [
                  this.candidateId
              ]
              })
            }
            this.templateModels.forEach((eachModel: FormModel) => {
              for(const data of userDataObj)
              {
                if (eachModel.fieldName === data.fieldName) {
                  eachModel.answer = data.answer;
                  this.ansChange = !this.ansChange;
                  eachModel.isValid = true;
                }
              }
            })
            _templateModels.forEach((model: FormModel) => {
              if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
                _displaySection = model.displaySection;
                sections.push(_displaySection);
              }
            })
            sections.forEach((eachSection: string) => {
              this.templateCollections.push(this.templateModels.filter((model: FormModel) => model.displaySection == eachSection));
              if(this.inEditMode)
              {
                this.ansChange = !this.ansChange;
              }
            })
            this.isLoading = false;
          })
        }
        else if(this.candidateId && this.formName === ' Registration Form')
        {
          this.inEditMode = true;
          this.isLoading = true;
          this.isLoading = true;
          this.eventService.getUserByIdNoAuth(this.candidateId).subscribe((responseData: any)=>{
            let candidateData = responseData.data;
            if(Object.keys(candidateData).length === 0 || responseData.status === 500)
            {
              this.isLoading = false;
              this.messageServices.add({ severity: 'error', summary: 'Error', detail: 'Invalid Url!' });
            }
            else
            {
              this.formBuilderServices.getCandidateFormDetails(this.candidateId, 'Registration Form').subscribe((res: any)=>{
                let userDataObj = res.data;
                let eligibility: boolean = false;
                let notEligibleDetails: boolean = false;
                let isPwd: boolean = false;
                userDataObj.forEach((each: any)=>{
                  if(candidateData.academicEligibility && each.fieldName === 'Do you have academic aggregate of 60% or above in 10th, 12th and UG/ PG?')
                  {
                    each.answer = [candidateData.academicEligibility];
                    eligibility = true;
                  }
                  if(candidateData.academicNotEligibleDetails && each.fieldName === 'At what level of education is/was your academic aggregate lower than 60%?')
                  {
                    each.answer = candidateData.academicNotEligibleDetails;
                    notEligibleDetails = true
                  }
                  if(candidateData.isPWD && each.fieldName === 'Do you consider yourself to have a disability (Persons with Disabilities Act, India 2016)?')
                  {
                    each.answer = [candidateData.isPWD];
                    isPwd = true
                  }
                });

                // console.log("eligibility notEligibleDetails isPwd", eligibility, notEligibleDetails, isPwd);

                if(eligibility == false && candidateData.academicEligibility)
                {
                  const obj = {
                    fieldName: 'Do you have academic aggregate of 60% or above in 10th, 12th and UG/ PG?',
                    answer: [candidateData.academicEligibility]
                  }
                  userDataObj.push(obj)
                }
                if(notEligibleDetails == false && candidateData.academicNotEligibleDetails)
                {
                  const obj = {
                    fieldName: 'At what level of education is/was your academic aggregate lower than 60%?',
                    answer: candidateData.academicNotEligibleDetails
                  }
                  userDataObj.push(obj)
                }
                if(isPwd == false && candidateData.isPWD)
                {
                  const obj = {
                    fieldName: 'Do you consider yourself to have a disability (Persons with Disabilities Act, India 2016)?',
                    answer: [candidateData.isPWD]
                  };
                  userDataObj.push(obj)
                }

                this.templateModels.forEach((eachModel: FormModel) => {
                  for(const data of userDataObj)
                  {
                    if (eachModel.fieldName === data.fieldName && data.answer.length) {
                      eachModel.answer = data.answer;
                      this.ansChange = !this.ansChange;
                      eachModel.isValid = true;
                      eachModel.disabled = true;
                    }
                  }
                })
                _templateModels.forEach((model: FormModel) => {
                  if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
                    _displaySection = model.displaySection;
                    sections.push(_displaySection);
                  }
                })
                sections.forEach((eachSection: string) => {
                  this.templateCollections.push(this.templateModels.filter((model: FormModel) => model.displaySection == eachSection));
                  if(this.inEditMode)
                  {
                    this.ansChange = !this.ansChange;
                  }
                })
                this.isLoading = false;
              })
            }
          })
        }
        else
        {
          _templateModels.forEach((model: FormModel) => {
            if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
              _displaySection = model.displaySection;
              sections.push(_displaySection);
            }
          })
          sections.forEach((eachSection: string) => {
            this.templateCollections.push(this.templateModels.filter((model: FormModel) => model.displaySection == eachSection));
          });
          this.isLoading = false;
        }
      }
    })
  }

  bindData(event: any, model: FormModel) {
    model.answer = [];
    switch (model.fieldType) {
      case 'checkbox': model.answer = event; break;
      case 'multiText': {
        (model.answer = event)
        if(model.fieldName == 'Alternate Email Address'){
          this.templateModels.forEach((eachModel: FormModel) => {
            if (eachModel.fieldName == 'Primary Email Address') {
              if(event.includes(eachModel.answer[0])){
                model.isValid = false;
                this.duplicateData.emailDuplicate = true;
              } else {
                this.duplicateData.emailDuplicate = false;
              }
            }
          })
        }
      }; break;
      case 'multiinput': model.answer.push(model.fieldOptions?.map((option: FieldOptions) => option.value).join(' ')); break;
      case 'mobile': model.answer = event; break;
      case 'dropdown': {
        if (model.fieldName == 'Highest Degree - Year of Passing' && event != 'Prior to 2020') {
            model.answer.push(Number(event))
        } else {
          model.answer.push(event)
        }
      } break;
      case 'input': {
        if (model.fieldName == 'Aadhar Card Number:') {
          model.answer.push(Number(event))
        } else {
          model.answer.push(event)
        }
      } break;
      default: model.answer.push(event);
    }
  }

  enableEmailVerification(event: any) {
    this.verifyChoice = '';
    this.emailToVerify = event.email;
    this.sendOtpByEmail();
    this.verifyChoice = 'email';
  }
  enableMobileVerification(event: any) {
    this.verifyChoice = '';
    this.mobileToVerify = event.mobile;
    this.countryCode = event.countryCode;
    this.mobileNumber = event.number;
    this.verifyChoice = 'mobile';
    this.sendOtpByMobile();
  }

  sendOtpByEmail = () => {
    // this.btnLoading = true;
    this.otpService.sendOtpByEmail(this.emailToVerify, this.eventId).subscribe(res => {
      if(res.data == 'OTP Sent Successfully'){
        // this.otpResponse = res.data;
        this.otpSent = true;
        this.loadResend = false; 
        this.waitToResend = true;
        this.startOtpTimer();
      } else {
        this.otpSent = false;
        this.messageServices.add({ severity: 'error', summary: 'Error', detail: 'Something Went Wrong!' });
      }
    })
  }
  sendOtpByMobile = () => {
    // this.btnLoading = true;
    let payload = {
      "mobileNumber" : this.mobileToVerify,
    }
    this.otpService.sendOtpByMobile(payload,'registrationForm').subscribe(res => {
      if(res.data){
        this.mobileOtp = res.data;
        this.otpSent = true;
        this.loadResend = false; 
        this.waitToResend = true;
        this.startMobileOtpTimer();
      } else {
        this.otpSent = false;
        this.messageServices.add({ severity: 'error', summary: 'Error', detail: 'Something Went Wrong!' });
      }
    })
  }

  verifyEmailOtp(){
      this.verifyLoading = true;
      if (this.otpValue.toString().length == 6 && this.enableVerify){
        let payload = { otp: Number(this.otpValue) };
        this.otpService.verifyOtp(this.emailToVerify, payload).subscribe(res => {
          if(res.data){
            this.verifyLoading = false;
            switch (res.data) {
              case 'success': {
                  this.messageServices.add({ severity: 'success', summary: 'Verified', detail: 'Email is Successfully Verified' });
                  this.p1EmailVerified = true;
                  this.dialogClose();
              } break;
              case 'failed': {
                this.messageServices.add({ severity: 'error', summary: 'Failed', detail: 'Please enter the valid OTP!' });
              } break;
              case 'expired': {
                this.messageServices.add({ severity: 'error', summary: 'Expired', detail: 'Your OTP is Expired!' });
              }
            }
          }
          else{
            this.otpValue = '';
            this.verifyLoading = false;
            this.p1EmailVerified = false;
            this.messageServices.add({ severity: 'error', summary: 'Expired', detail: 'Something Went Wrong!' });
          }
          // this.btnLoading = false;
        })
      }
  }

  verifyCheck(event:any){
    if(this.otpValue.length == 6 && !isNaN(Number(event))){
      this.enableVerify = true;
    }else{
      this.enableVerify = false;
    }
  }

  dialogClose(){
    // this.otpResponse = {};
    this.mobileOtp = {};
    this.otpSent = false;
    this.enableVerify = false;
    this.verifyLoading = false;
    this.waitToResend = false;
    this.otpValue = '';
    this.otpTimer = 0;
    clearInterval(this.intervalId);
  }

  verifyMobileOtp(){
      this.verifyLoading = true;
      if (this.otpValue.toString().length == 6 && this.enableVerify){
        let payload = { mobileNumber : this.mobileToVerify, otp: Number(this.otpValue) };
        this.otpService.verifyOtpByMobile(payload,'registrationForm').subscribe(res => {
          if(res.data){
            this.verifyLoading = false;
            switch (res.data) {
              case 'success': {
                  this.messageServices.add({ severity: 'success', summary: 'Verified', detail: 'Mobile Number is Successfully Verified' });
                  this.p1MobileVerified = true;
                  this.dialogClose();
              } break;
              case 'failed': {
                this.messageServices.add({ severity: 'error', summary: 'Failed', detail: 'Please enter the valid OTP!' });
                // this.invalidOTP = true;
              } break;
              case 'expired': {
                this.messageServices.add({ severity: 'error', summary: 'Expired', detail: 'Your OTP is Expired!' });
                // this.expiredOTP = true;
              }
            }
          }
          else{
            this.otpValue = '';
            this.enableVerify = false;
            this.verifyLoading = false;
            this.p1MobileVerified = false;
            this.messageServices.add({ severity: 'error', summary: 'Expired', detail: 'Something Went Wrong!' });
          }
        })
      }
  }

  resendEmailOtp = () => {
    this.loadResend = true; 
    this.sendOtpByEmail();
  }
  resendMobileOtp = () => {
    this.loadResend = true; 
    this.sendOtpByMobile();
  }

  startOtpTimer() {
    this.otpTimer = 60;
    this.countdown = this.formatTime(this.otpTimer);
    this.waitToResend = true;

    this.intervalId = setInterval(() => {
      this.otpTimer--;
      this.countdown = this.formatTime(this.otpTimer);

      if (this.otpTimer === 0) {
        clearInterval(this.intervalId);
        this.waitToResend = false;
      }
    }, 1000);
  }
  startMobileOtpTimer() {
    this.otpTimer = 60;
    this.countdown = this.formatTime(this.otpTimer);
    this.waitToResend = true;

      this.intervalId = setInterval(() => {
      this.otpTimer--;
      this.countdown = this.formatTime(this.otpTimer);

      if (this.otpTimer === 0) {
        clearInterval(this.intervalId);
        this.waitToResend = false;
      }
    }, 1000);
  }

  formatTime(seconds: number): string {
    return `${seconds.toString().padStart(2, '0')}`;
  }

  verifyOnSubmit() {
    if(this.formName === 'Registration Form' || this.formName === ' Registration Form' || this.formName === 'Registration Form Non-Technical'){
      if(this.enableMobile){
        if(this.p1EmailVerified && this.p1MobileVerified){
          // if (this.validateRegistration()) {
          //   let currentDate = new Date().toString();
          //   console.log("currentDate", currentDate);
          //   if (currentDate >= this.registrationStartDate && currentDate <= this.registrationEndDate) {
          //     this.submit();
          //   }
          //   else {
          //     (this.formName == 'Registration Form' || this.formName == 'Registration Form Non-Technical') ? this.formExpired = true : this.submit();          
          //   }
          // }
          // else {
          //   this.submit();
          // }
          this.submit();
        }else{
          if(!this.p1EmailVerified){
            this.messageServices.add({ severity: 'error', summary: 'Error', detail: 'Please verify your email address' });
          }else if(!this.p1MobileVerified){
            this.messageServices.add({ severity: 'error', summary: 'Error', detail: 'Please verify your mobile number.' });
          }
        }  
      }else{
        if(this.p1EmailVerified){
          // if (this.validateRegistration()) {
          //   let currentDate = new Date().toString();
          //   if (currentDate >= this.registrationStartDate && currentDate <= this.registrationEndDate) {
          //     this.submit();
          //   }
          //   else {
          //     (this.formName == 'Registration Form' || this.formName == 'Registration Form Non-Technical') ? this.formExceeded = true : this.submit();       
          //   }
          // }
          // else {
          //   this.submit();
          // }
          this.submit();
        }else{
          this.messageServices.add({ severity: 'error', summary: 'Error', detail: 'Please verify your email address' });
        }
      }
    } else {
      this.submit();
    }
  }

  submit() {
    this.clicked = true;
    let templateModels = this.templateCollections.flat(1).filter((eachModel: FormModel) => eachModel.visible);
    let validArray = templateModels.map((model: FormModel) => model.isValid);
    this.isFormValid = !(validArray.includes(false) || validArray.includes(undefined));
    if (this.isFormValid) {
      this.submitBtnLoading = true;
      let payload = templateModels;
      this.duplicateData = {};
      let conditionOption: any = {};
      this.templateModels.forEach((each)=>{
        delete each['disabled'];
        if(each.fieldName === 'Alternate Email Address')
        {
          conditionOption['email'] = each.answer[0];
        }
        else if(each.fieldName === 'Alternate Mobile Number')
        {
          conditionOption['countryCode'] = each.answer[0];
          conditionOption['mobile_number'] = each.answer[1]
        }
      })
      if(this.schoolCode === 'wileyedge399' || this.schoolCode === 'wileyedge537' || this.schoolCode === 'nstech196')
      {
        this.sourceTag = 'P1-Form-mthree'
      }
      else
      {
        this.sourceTag = this.eventTitle ?? (this.sourceTag ?? '-');
      }
      if(this.formName === "Offer Acceptance and Joining Confirmation Form")
      {
        let payload = templateModels;
        this.formBuilderServices.saveOffer(payload, this.jobUserMappingId).subscribe((res: any)=>{
          this.submitBtnLoading = false;
          if (res.status == 200) {
            this.offerFormSuccess = true;
            this.clicked = false;
          }
          else if(res.status == 500) { 
            this.offerFormWarning= true;
            let errorMessage = res?.error?.message
            if(errorMessage)
            {
              if(errorMessage === 'Invalid Url')
              {
                this.invalidUrl = true;
              }
            }
          }
        })
      }
      else
      {
        this.formBuilderServices.saveUser(payload, (this.formName == ' Registration Form') ? 'p1resubmission' : (this.formName == 'Registration Form' || this.formName == 'Registration Form Non-Technical') ? 'P1' : this.formName == 'Extended Details and Documentation Form' ? 'P2' : 'offerForm', this.eventId, this.eventTitle, conditionOption, this.sourceTag, this.candidateId).subscribe(res => {
          this.submitBtnLoading = false;
          if (res.status == 200) {
            this.submittedSuccessfully = true;
            this.clicked = false;
          } else if (res.status == 500) {
            let errorData: any = {}
            if(res?.error?.includes('noUserFound'))
            {
              errorData['userNotFound'] = true
            }
            if(res?.error?.includes('duplicateMobileNumber'))
            {
              errorData['mobileDuplicate'] = true
            }
            if(res?.error?.includes('duplicateEmail'))
            {
              errorData['emailDuplicate'] = true
            }
            if(res?.error == 'P2 form already submitted for user' || res?.error == 'P1 form already submitted for user' )
            {
              this.existingFormSubmit = true;
            }
            this.duplicateData = {...errorData};
            if(Object.keys(this.duplicateData).length === 0)
            {
              if(res?.error === "Mobile Number Already Exists")
              {
                this.mobileExists = true;
              }
              if(res?.error === "Email Already Exists")
              {
                this.emailExists = true;
              }
              if(res?.error === "Email and Mobile Number Already Exists")
              {
                this.mobileExists = true;
                this.emailExists = true;
              }
            }
            else{
              this.invalidField = {...this.templateModels[0]};
            }
          }
          else {
            this.messageServices.add({ severity: 'error', summary: 'Failed', detail: 'Something went wrong!' });
          }
        })
      }
    } else {
      let invalidFields = templateModels.filter((model: FormModel) => !model.isValid);
      this.invalidField = invalidFields[0];
    }

  }

  checkValid(event: any, model: FormModel) {
    model.isValid = event;

    // if (model.fieldName == "Preferred Location 2") {
    //   let templateModels = this.templateCollections.flat(1);
    //   let matchingField: any;
    //   templateModels.forEach((each: FormModel) => { if (each.fieldName == 'Preferred Location 1') matchingField = each })

    //   if (matchingField.answer[0] == model.answer) {
    //     model.isValid = false;
    //     this.inValidLocation = true;
    //   } else {
    //     model.isValid = true
    //     this.inValidLocation = false;
    //   };
    // }

    if(model.formName == 'Registration Form' || model.formName == ' Registration Form' || model.formName == 'Registration Form Non-Technical')
    {
      if(model.fieldName == "Primary Email Address" || model.fieldName == "Alternate Email Address"){
        this.templateModels.forEach((eachModel: FormModel) => {
          if (eachModel.fieldName == 'Alternate Email Address') {
            if( this.duplicateData.emailDuplicate != undefined && !this.duplicateData.emailDuplicate && eachModel.isValid){
              eachModel.isValid = true;
            } else {
              eachModel.isValid = true;
            }
          }
        })
      }
    }

    if (model.fieldName == "Date Of Birth") {
      let year = new Date().getFullYear();
      let dif = year - Number(model.answer[0].split('-')[2]);

      if (dif < 18) {
        model.isValid = false;
        this.belowAge = true;
      } else {
        model.isValid = true;
        this.belowAge = false;
      }
    }
  }

  closeWindow(action: string) {
    if (action === 'success') {
      window.close();
    }
    else {
      this.emailExists = false;
      this.mobileExists = false;
    }
  }

  checkForDependancy = (model: FormModel): boolean => {
    if (model.dependant) {
      let templateModels = this.templateCollections.flat(1);
      let dependantField: any;

      templateModels.forEach((eachModel: FormModel) => {
        if (eachModel.fieldName === model.dependant?.dependantOn) dependantField = eachModel;
      })

      if (model.dependant.dependantValues.includes(dependantField.answer[0])) {
        model.visible = true;
        this.invalidField = {};
        return true;
      }
      else {
        model.visible = false;
        model.answer = [];
        model.isValid = model.isMandatory ? false : true;
        return false;
      }
    } else return true;
  }

  prefillForm()
  {
    if(this.otpVerified && this.formName === 'Extended Details and Documentation Form')
    {
      this.getTemplates();
    }

  }
}
