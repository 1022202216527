<div>
  <div *ngIf="isLoading" class="{{isLoading ? 'loadingOverlay' : ''}}">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>
  <div class="live-chat-header">
    <div class="detail-panel">
      <div>
        <div class="detail-container">
          <div class="container-flex" style=" border-right: 2px solid darkgrey;">
            <div class="can_label">Candidate ID : </div><span class="can_data">{{candidateDetails.id ? candidateDetails.id : '-'}}</span>
          </div>
          <div class="container-flex" style=" border-right: 2px solid darkgrey;">
            <div class="can_label">Candidate Name : </div><span class="can_data">{{candidateDetails.name ? candidateDetails.name : '-'}}</span>
          </div>
          <div class="container-flex" style=" border-right: 2px solid darkgrey;">
            <div class="can_label">College : </div><span class="college_data" style="
            width: 500px;" (mouseenter)="collegeName.show($event)">{{candidateDetails.collegeName ? candidateDetails.collegeName : '-'}}</span>
          </div>
          <div class="container-flex" style=" border-right: 2px solid darkgrey;">
            <div class="can_label">Degree :</div><span class="degree_data" (mouseenter)="degreeName.show($event)">
              {{candidateDetails.degree ? candidateDetails.degree : '-'}}</span>
          </div>
          <div class="container-flex">
            <div class="can_label">Branch :</div><span class="branch_data">
              {{candidateDetails.degreeBranch ? candidateDetails.degreeBranch : '-'}}</span>
          </div>
        </div>
        <p-overlayPanel #collegeName [dismissable]="true">
          <ng-template pTemplate>
              <div class="remainingItems" style="padding: 10px;max-width: 500px;" (mouseenter)="collegeName.show($event)"
              (mouseleave)="collegeName.hide()">
                  <p>{{candidateDetails.collegeName}}</p>
              </div>
          </ng-template>
      </p-overlayPanel>
        <p-overlayPanel #degreeName [dismissable]="true">
          <ng-template pTemplate>
              <div class="remainingItems" style="padding: 10px;max-width: 500px;" (mouseenter)="degreeName.show($event)"
              (mouseleave)="degreeName.hide()">
                  <p>{{candidateDetails.degree}}</p>
              </div>
          </ng-template>
      </p-overlayPanel>
      </div>
    </div>
    <div class="header-btns">
      <button pButton class="joinBtn" label="Feedback" (click)="getAllRoles()"></button>
    </div>
  </div>
  <div class="score-head" *ngIf="candidateAssessmentDetails.length > 0" id="score-head">
    <div class="score-container" style="display: flex;overflow: hidden; width: 90%;font-size: 15px;" id="score-container">
      <div class="assessment" *ngFor="let item of candidateAssessmentDetails,index as i">
        <div class="flex-text-wrap">
          <span class="score-number">{{i+1}}</span>
          <span>{{item.stageName ? item.stageName : '-'}}</span>
        </div>
        <div class="flex-text-wrap">
          <div (click)="getResultAnalysis(item.testId)" class="pi pi-chart-bar scort-chart"></div>
          <label class="score-label">(Total: {{isNumber(item.marks) ? item.marks : '-'}}/{{item.total_marks ? item.total_marks : '-'}})</label>
          <i title="Test Info" class="pi pi-info-circle test-chart" (click)="toggleFunction(op,$event);"></i>
        </div>
        <p-overlayPanel #op [style]="{'width': '350px'}">
          <ng-template pTemplate *ngIf="item.section_wise_marks.length > 0">
            <div class="section-container" *ngFor="let section of item.section_wise_marks"
            style="padding: 10px 40px 10px 15px;
            display: flex; color: black;
            justify-content: space-between;
            align-items: center;">
              <div class="flex-text-wrap">
                <span>{{section.name}} Score</span>
              </div>
              <div class="flex-text-wrap">
                <span>{{isNumber(section.marks) ? section.marks : '-'}} / {{isNumber(section.total_marks) ? section.total_marks : '-'}}</span>
              </div>
            </div>
          </ng-template>
      </p-overlayPanel>
      </div>
    </div>
    <div class="mark-arrows" *ngIf="showBackNext === true">
      <div class="arrow-container" >
        <div class="back-btn" (click)="handleSectionTabBack()" style="display: flex;">
          <i title="Back" class="pi pi-angle-left back-arrow"></i>
        </div>
        <div class="next-btn"(click)="handleSectionTabNext()" style="display: flex;">
          <i title="Forward" class="pi pi-angle-right front-arrow"></i>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="meetingEnd && !adminReload" class="candidate-body-content">
    Your Interview has been Completed.
  </div>
  <div *ngIf="adminReload" class="candidate-body-content">
    Reloading Interview.
  </div>
  <div id="jaas-container" class="live-chat"
    style="height:80vh; background-color: black; margin-top: 0.5rem; display: none;">
  </div>

  <p-sidebar model="true" class="addRole-slider" [style]="{width: '55rem'}" [(visible)]="feedbackSlider"
    styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right">
    <div style="position:relative; padding: 20px 50px; background-color: #F8F8F8;">
      <app-feedback-form [headerData]="paramItems" [formName]="formName"></app-feedback-form>
    </div>
  </p-sidebar>

</div>