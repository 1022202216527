<div class="event-header">
    <div class="status">
        <p-splitButton label="Status: {{ statusSelected }}" [model]="this.status"
            styleClass="p-button-sm mr-2 mb-2 p-button-outlined p-button-secondary"></p-splitButton>
    </div>
    <div>
        <p-sidebar class="addEvent-slider" [(visible)]="addEventSlider" styleClass="p-sidebar-lg" [showCloseIcon]="true"
            position="right" (onHide)="getAllEvents()" [style]="{ width: '60vw' }">
            <div *ngIf="addEventSlider">
                <app-events-add *ngIf="cloneEventData?.id == undefined"></app-events-add>
                <app-events-add *ngIf="cloneEventData?.id?.length" [eventCloneData]="cloneEventData" [eventJobData]="eventJobData"  (eventCloned)="eventCloned($event)" ></app-events-add>

            </div>
        </p-sidebar>
        <button *ngIf="privilege.addNewEvents" pButton pRipple class="p-button-raised" type="button"
            (click)="addEventSlider = true" label="New Event"></button>
    </div>
</div>

<div class="table">

    <!-- Filter SideBar -->
    <p-sidebar class="filterSidebar" [transitionOptions]="'0ms'" [(visible)]="showFilterSidebar"
        (onHide)="getAllEvents()" [style]="{padding: '0', border: '2px solid white'}" [modal]="false" [baseZIndex]="0"
        ariaCloseLabel="Filter">
        <p class="sidebar-title">Filter</p>
        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input type="text" pInputText placeholder="Search" [(ngModel)]="filterSearchTerm">
        </span>

        <div class="filterSidebarContent">
            <div class="sidebar-bottom">
                <div class="sidebar-bottom-apply">
                    <p class="sidebar-bottom-text" (click)="displaySaveFilterSettings = true;">Save Selected Filters</p>
                    <div class="sidebar-bottom-buttons">
                        <button pButton pRipple type="button" label="Clear" class="p-button-outlined"></button>
                        <button pButton pRipple type="button" label="Apply"></button>
                    </div>
                </div>

                <div class="sidebar-saveSettings" [ngClass]="{'hidden' : !displaySaveFilterSettings}">
                    <em class="pi pi-times" (click)="displaySaveFilterSettings = false"></em>
                    <div class="p-inputgroup">
                        <input type="text" pInputText placeholder="Enter Filter Name">
                        <button type="button" pButton pRipple label="Save"></button>
                    </div>
                </div>
            </div>
        </div>
    </p-sidebar>

    <!-- Column Settings Sidebar -->
    <p-sidebar class="settingsSidebar" [transitionOptions]="'0ms'" [(visible)]="showSettingsSidebar"
        [style]="{ padding: '0', border: '2px solid white'}" [modal]="false">
        <p class="sidebar-title">Column Settings</p>

        <div class="{{savedColumnSettings.length ? 'savedColumns-continer' : 'hidden'}}">
            <p>Saved Column</p>
            <div class="savedColumns" *ngFor="let column of savedColumnSettings"
                (click)="applySavedSettings(column.columnValue)">
                <p>{{column.columnName}}</p>
                <em class="pi pi-trash" (click)="deleteSavedColumns(column.columnId)"></em>
            </div>
        </div>
        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input type="text" pInputText placeholder="Search" [(ngModel)]="columnSearchTerm"
                (ngModelChange)="searchColumns($event)" />
        </span>

        <div *ngFor="let col of _eventsCol" class="field-checkbox">
            <p-checkbox name="category" [value]="col" [(ngModel)]="checkedColumns" [inputId]="col.field"></p-checkbox>
            <label [for]="col.field" [style]="{paddingLeft: '10px'}">{{col.header}}</label>
        </div>

        <div class="sidebar-bottom">

            <div class="{{ displaySaveColumnSettings ? 'hidden' : 'sidebar-bottom-apply'}}">
                <p class="sidebar-bottom-text" (click)="displaySaveColumnSettings = true">Save Selected Columns</p>
                <div class="sidebar-bottom-buttons">
                    <button pButton pRipple type="button" label="Clear" class="p-button-outlined"
                        (click)="clearSelectedColumns()"></button>
                    <button pButton pRipple type="button" label="Apply" (click)="applySelectedColumns()"></button>
                </div>
            </div>

            <div class="sidebar-saveSettings" [ngClass]="{'hidden': !displaySaveColumnSettings}">
                <em class="pi pi-times" (click)="displaySaveColumnSettings = false"></em>
                <form class="p-inputgroup" [formGroup]="columnSettings" (ngSubmit)="saveColumnSettings()">
                    <input type="text" pInputText placeholder="Enter Filter Name" formControlName="columnName">
                    <button type="submit" pButton pRipple label="Save"></button>
                </form>
            </div>
        </div>
    </p-sidebar>

    <!-- Download Sidebar -->
    <p-sidebar class="downloadSidebar eventListDownload" [transitionOptions]="'0ms'" [(visible)]="showDownloadSidebar"
        [style]="{ padding: '0', border: '2px solid white'}" [modal]="false">
        <p class="sidebar-title">Download Data</p>

        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input type="text" pInputText placeholder="Search" [(ngModel)]="downloadSearchTerm"
                (ngModelChange)="searchColumns($event)" />
        </span>

        <div class="field-checkbox" *ngFor="let col of colsToDownload">
            <p-checkbox name="category" [value]="col" [(ngModel)]="checkedColumns" [inputId]="col.field"></p-checkbox>
            <label [style]="{paddingLeft: '10px'}" [for]="col.field">{{col.header}}</label>
        </div>

        <div class="download-container">
            <p>Download as</p>
            <div class="radioButton-group">
                <div class="radioButton">
                    <p-radioButton name="exportButtons" value="excel" inputId="excel" [(ngModel)]="downloadAs">
                    </p-radioButton>
                    <label for="excel"
                        [style]="{color: downloadAs === 'excel' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
                            class="pi pi-file"></em>Excel</label>
                </div>
                <div class="radioButton">
                    <p-radioButton name="exportButtons" value="csv" inputId="csv" [(ngModel)]="downloadAs">
                    </p-radioButton>
                    <label for="csv"
                        [style]="{color: downloadAs === 'csv' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
                            class="pi pi-file-excel"></em>CSV</label>
                </div>
            </div>

            <button pButton pRipple type="button" label="Download" class="p-button-raised"
                (click)="(downloadAs === 'excel') ? exportEvent('Excel') : exportEvent('csv')"></button>
        </div>

    </p-sidebar>

    <p-table #dt [columns]="_selectedColumns" [value]="eventsList" [resizableColumns]="true" [rows]="pageSize"
        [loading]="loadingSpinner" [scrollable]="true" scrollHeight="75vh" scrollDirection="both"
        responsiveLayout="scroll" dataKey="id" [selection]="selectedEvents" [selection]="selectedEvents"
        (selectionChange)="onSelectedEventChange($event)" [exportFilename]="exportName">

        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="table-header-left">
                    <button pButton class="p-button-text simple-btn{{showSettingsSidebar ? '-active' : '' }}"
                        icon="pi pi-sliders-h"
                        (click)="showSettingsSidebar = true; showFilterSidebar = false; showDownloadSidebar = false">
                    </button>
                    <!-- <button pButton class="p-button-text simple-btn{{showFilterSidebar ? '-active' : '' }}"
                        icon="pi pi-filter"
                        (click)="showFilterSidebar = true; showSettingsSidebar = false; showDownloadSidebar = false">
                    </button> -->
                    <button pButton class="p-button-text simple-btn{{showDownloadSidebar ? '-active' : '' }}"
                        icon="pi pi-download"
                        (click)="showDownloadSidebar = true; showSettingsSidebar = false; showFilterSidebar = false">
                    </button>
                    <p-divider layout="vertical"></p-divider>
                    <label>Total List: <span [style]="{color: '#333333'}">{{ totalLength }}</span></label>
                </span>
                <span class="table-menu">
                    <p [style]="{paddingRight: '10px'}">View</p>
                    <p-dropdown [options]="pageLimits" [(ngModel)]="pageSize" (onChange)="getEventsByLimit()"
                        [style]="{ maxWidth: '5rem', fontSize: '0.9rem' }"></p-dropdown>
                    <p-divider layout="vertical"></p-divider>
                    <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                        {{ first }} to {{ last }}
                    </button>
                    <button pButton (click)="prev()" [disabled]="isFirstPage()" icon="pi pi-angle-left"
                        class="p-button-secondary p-button-text simple-btn"></button>
                    <button pButton (click)="next()" [disabled]="isLastPage()" icon="pi pi-angle-right"
                        class="p-button-secondary p-button-text simple-btn"></button>
                </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr class="table-heading"
                [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}">
                <th scope="col" [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th [style]="{width: '15vw'}" scope="col" pSortableColumn="eventTitle" pResizableColumn pFrozenColumn>
                    Event Name
                    <p-sortIcon class="sort-icon" field="eventTitle"></p-sortIcon>
                </th>
                <th [style]="{width: '15vw'}" scope="col" pSortableColumn="{{ event.field }}"
                    *ngFor="let event of columns" pResizableColumn>
                    {{ event.header }}
                    <p-sortIcon class="sort-icon" field="{{ event.field }}"></p-sortIcon>
                </th>
            </tr>
            <tr class="selection-operations-row" *ngIf="selectedEvents.length">
                <td pFrozenColumn class="selection-operations-options">
                    <span>{{selectedEvents.length}} Selected</span>
                    <button pButton pRipple label="Delete Event" (click)="deleteEvent(selectedEvents[0])" [style]="{fontWeight: '500'}"
                        [disabled]="selectedEvents.length > 1 || selectedEvents[0].candidateCount > 0"></button>
                    <button pButton pRipple label="Clone Event" (click)="cloneEvent(selectedEvents[0])"
                        [style]="{fontWeight: '500'}" [disabled]="selectedEvents.length > 1" ></button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-event let-columns="columns">
            <tr class="row-event-list"
                [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}"
                [style]="{cursor: 'pointer'}">
                <td [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
                    <p-tableCheckbox [value]="event"></p-tableCheckbox>
                </td>
                <td (click)="openCandidates(event.eventTitle, event.id, event.eventCategory ?? event.category)" [style]="{width: '15vw'}" pFrozenColumn>{{
                    event.eventTitle }}</td>

                <td (click)="openCandidates(event.eventTitle, event.id, event.eventCategory ?? event.category)" *ngFor="let col of columns"
                    [style]="{width: '15vw'}">
                    <span [ngSwitch]="true">

                        <span *ngSwitchCase="col.field === 'status'" class="status-tag {{ event[col.field] }}-tag">
                            {{ event[col.field].charAt(0).toUpperCase() + event[col.field].slice(1) }}
                        </span>

                        <span *ngSwitchCase="col.field === 'jobRole'" class="jobRole-op">
                            <span *ngIf="event[col.field] != null; else elseContent">
                                {{ event[col.field][0]?.roleName ?
                                event[col.field][0].roleName : event[col.field][0]
                                }}
                                <div *ngIf="event[col.field].length > 1" class="remainingItems-badge">
                                    <p-badge value="+{{event[col.field].length - 1}}" styleClass="mr-2"
                                        (mouseenter)="roles_op.show($event)"></p-badge>
                                </div>
                            </span>
                            <ng-template #elseContent>-</ng-template>
                            <p-overlayPanel #roles_op [dismissable]="true">
                                <ng-template pTemplate>
                                    <div class="remainingItems" (mouseenter)="roles_op.show($event)"
                                        (mouseleave)="roles_op.hide()">
                                        <p *ngFor="let roles of event[col.field].slice(1)">{{roles.roleName ?
                                            roles.roleName : roles }}</p>
                                    </div>
                                </ng-template>
                            </p-overlayPanel>
                        </span>

                        <span
                            *ngSwitchCase="col.field == 'eventStartDate' || col.field == 'eventEndDate' || col.field == 'registrationStartDate' || col.field == 'registrationEndDate' || col.field == 'createdAt'">
                            {{ event[col.field] | date: dateFormat || '-' }}
                        </span>

                        <span *ngSwitchCase="col.field === 'institutionName'">
                            <span *ngIf="event['eventType'] == 'poolCampus'; else instituteName" class="jobRole-op">
                                <span *ngIf="event[col.field] != null; else elseContent">
                                    {{ event[col.field][0] ? event[col.field][0] : '-' }}
                                    <div class="remainingItems-badge">
                                        <p-badge value="+{{event[col.field].length - 1}}" styleClass="mr-2"
                                            (mouseenter)="institutions_op.toggle($event)"
                                            (mouseleave)="institutions_op.hide()">
                                        </p-badge>
                                    </div>
                                </span>
                                <ng-template #elseContent>-</ng-template>
                                <p-overlayPanel #institutions_op>
                                    <ng-template pTemplate>
                                        <div class="remainingItems">
                                            <p *ngFor="let roles of event[col.field].slice(1)">{{roles}}</p>
                                        </div>
                                    </ng-template>
                                </p-overlayPanel>
                            </span>
                            <ng-template #instituteName>
                                <span>
                                    {{event[col.field]?.name ? event[col.field].name : event[col.field]}}
                                </span>
                            </ng-template>
                        </span>
                        <span *ngSwitchCase="col.field == 'candidateCount' || col.field == 'eligible' || col.field == 'notEligible' || col.field == 'appliedCount'">
                            {{ event[col.field] ? event[col.field] : '0' }}
                        </span>
                        
                        <span *ngSwitchDefault>
                            {{ event[col.field] ? event[col.field] : '-' }}
                        </span>
                    </span>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td>No Records Found</td>
            </tr>
        </ng-template>
    </p-table>
</div>