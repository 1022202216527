<div class="userList-header">
    <div class="header-tabs">
        <button class="tabs" [ngClass]="{'selected': displayCandidates}" (click)="showCandidates()" *ngIf="privilege.viewManageEventsCandidate">Candidates</button>
        <button class="tabs" [ngClass]="{'selected': displayJobDetails}" (click)="showJobDetails()" *ngIf="privilege.viewManageEventsDetails">{{ isInEvent ?
            'Event' : 'Job'}} Details</button>
        <button class="tabs" [ngClass]="{'selected': displayWorkflow}" (click)="showWorkflow()">Stages</button>
        <button class="tabs" [ngClass]="{'selected': displayInterview}" (click)="showInterview()" *ngIf="privilege.viewManageEventsInterview">Interview</button>
    </div>
    <div class="link-add-candidate" *ngIf="displayCandidates">
        <span (click)="copyMessage()" [life]="2000" tooltipEvent="focus" pTooltip="Link Copied" tooltipPosition="top">
            <div class="event-link">Copy Registration Link <p class="pi pi-link link-icon"></p>
            </div>
        </span>
        <div>
            <p-splitButton label="Add Candidates" class="addCandidates-button" styleClass="p-button-sm mr-2 mb-2"
                (onClick)="addCandidateForm()" [model]="this.bulkUpload"></p-splitButton>
            <!-- <button pButton pRipple class="p-button-raised" type="button" (click)="addCandidateForm()" label="Add Candidates"></button> -->
            <p-sidebar class="addBulk-slider" [(visible)]="showBulkUpload" styleClass="p-sidebar-lg"
                [showCloseIcon]="true" position="right" [style]="{ width: '50vw' }" (onHide)="sidebarClose()">
                <div [style]="{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '25px', marginLeft: '50px'}">
                    <div class="bulk-header" [style]="{marginRight: '10px'}">Invite Candidate</div>
                    <div class="send-invite_btn">
                        <button pButton pRipple type="submit" (click)="sendInvite()" label="Send Invite"
                          class="button-align send-invite" [loading]="loadSingleInvite"></button>
                    </div>
                </div>
                <p-divider class="p-divider"></p-divider>

                <form [formGroup]="bulkInviteForm" class="bulk-invite-form">
                    <div class="bulkContainer">
                        <p class="template-download-label">Download Template to Invite Candidates</p>
                        <div>
                          <div class="template-download">
                            <div class="template-file_info">
                              <p class="pi pi-file-excel template-file-icon"></p>
                              <p class="file-name">Sample_Bulk_Invite.xlsx</p>
                            </div>
                            <p (click)="sampleData()" class="template-download-icon"><em class="pi pi-arrow-down"></em></p>
                          </div>
                        </div>
                        <p class="template-upload-label">Upload File to Invite Candidates in Bulk</p>
                        <div class="profilePhoto form-details">
                        <div *ngIf="!cohortUploaded">
                            <div class="bulkUpload-dropzone" ngx-dropzone
                            [accept]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
                            [multiple]="false" (change)="cohortUpload($event)">
                                <ngx-dropzone-label *ngIf="!uploadingFile">
                                    <div class="dropzone-label">
                                        <em class="pi pi-cloud-upload"></em>
                                        <p class="upload-text">Click to <span [style]="{color: 'var(--fc-primary)'}">upload</span>
                                            or
                                            Drag & Drop</p>
                                        <p class="format-text">.XLS, .XLSX Formate only</p>
                                    </div>
                                </ngx-dropzone-label>
                                <div *ngIf="uploadingFile">
                                    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
                                    </p-progressSpinner>
                                </div>
                            </div>  
                        </div>
                        <div *ngIf="cohortUploaded">
                            <div class="flex-dev uploaded-container" [style]="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">
                                <div class="bulk-main" [style]="{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}">
                                    <div class="file-icon" [style]="{display: 'flex', justifyContent: 'center', alignItems: 'center',
                                        background: 'rgba(52, 86, 255, 0.03)',borderRadius: '4px', width: '40px', height: '40px',marginRight: '15px'}">
                                            <p class="pi pi-file template-file-icon" [style]="{fontSize : '24px',display: 'flex',alignItems: 'center', color: 'blue'}"></p>
                                    </div>
                                    <div class="cohort-file_info" [style]="{marginRight: '15px'}">
                                        <p class="file-name">{{cohortFile[0].file[0].name}}</p>
                                        <p class="file-name" [style]="{color: '#AAAAAA'}">{{cohortFileSize}}</p>
                                    </div>
                                    <div class="cancel" style="padding: 3px;" (click)="onRemoveExcel()">
                                        <p class="cohort-cancel-icon">
                                        <em class=" report-download pi pi-times" [style]="{fontSize: '14px', fontWeight: 'bold', color: '#d5d5d5', 
                                        display: 'flex', alignItems: 'center', justifyContent: 'center'}"></em></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="countDiv" class="records-container" [style]="{background: 'rgba(52, 86, 255, 0.03)',borderRadius: '4px', 
                                width: '303px', height: '40px', marginTop : '25px', marginBottom : '25px', display: 'flex', alignItems: 'center'}">
                                <div class="records" [style]="{width: '100%', display: 'flex',justifyContent: 'space-evenly', alignItems : 'center'}">
                                    <div class="total" [style]="{color: '#000000', fontWeight: 500, fontSize: '12px', marginRight: '15px'}">
                                        <label class="file-name">Total records :
                                          <span class="total-mark"> {{counts?.total}} </span>
                                        </label>
                                    </div>
                                    <div class="correct" [style]="{fontWeight: 500, fontSize: '12px', marginRight: '15px'}">
                                        <label class="file-name" [style]="{color: '#008000'}">Correct :
                                          <span class="total-mark" [style]="{color: '#000000'}"> {{counts?.validated}} </span>
                                        </label>
                                    </div>
                                    <div class="totalErrors" [style]="{fontWeight: 500, fontSize: '12px', marginRight: '15px'}">
                                        <label class="file-name" [style]="{color: '#FF5E5B'}">Error :
                                          <span class="total-mark" [style]="{color: '#000000'}"> {{counts?.errored}} </span>
                                        </label>
                                    </div>
                                </div>
                        </div>
                        <div *ngIf="messageEnable" class="notes">
                          <label class="heading" [style]="{color: '#000000',fontWeight: 'bold', fontSize: '14px'}">Note : 
                            <span class="total-mark" [style]="{fontWeight: 'lighter'}" *ngIf="counts?.errored > 0">
                              Kindly resolve or remove all the error in the main file to proceed to the next step.
                              <span [style]="{color: '#3456FF', cursor: 'pointer'}" (click)="errorReportDownload()">Download Error Report </span>
                            </span>
                          </label>
                        </div>
                        </div>
                      </div>
                </form>
                
            </p-sidebar>
        </div>
    </div>
</div>

<div class="search-container">
    <div *ngIf="displayCandidates" class="stage-search">
        <span class="p-input-icon-left">

            <em class="pi pi-search" style="margin-left: 35px;"></em>
            <div style="margin-left: 25px; width: 300px; margin-top: 5px; margin-bottom: 5px;">
                <input class="searchbar" (keyup.enter)="candidateSearch($event)" type="text"
                    pInputText placeholder="Search Email" />
            </div>
        </span>
    </div>
</div>


<div class="table" *ngIf="displayCandidates">

    <!-- Column Settings Sidebar -->
    <p-sidebar class="settingsSidebar" [transitionOptions]="'0ms'" [(visible)]="showSettingsSidebar"
        [style]="{ padding: '0'}" [modal]="false">
        <p class="sidebar-title">Column Settings</p>
        <div class="{{(savedColumnSettings && savedColumnSettings.length) ? 'savedColumns-continer' : 'hidden'}}">
            <p>Saved Column</p>
            <div class="savedColumns" *ngFor="let column of savedColumnSettings"
                (click)="applySavedSettings(column.columnValue)">
                <p>{{column.columnName}}</p>
                <em class="pi pi-trash" (click)="deleteSavedColumns(column.columnId)"></em>
            </div>
        </div>
        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input type="text" pInputText placeholder="Search" [(ngModel)]="columnSearchTerm"
                (ngModelChange)="searchColumns($event)" />
        </span>
        <div *ngFor="let col of _usersCol" class="field-checkbox">
            <p-checkbox name="category" [value]="col" [(ngModel)]="checkedColumns" [inputId]="col.field"></p-checkbox>
            <label [for]="col.field" [style]="{paddingLeft: '10px'}">{{col.header}}</label>
        </div>

        <div class="sidebar-bottom">
            <div class="sidebar-bottom-apply" [ngClass]="{'hidden' : displaySaveColumnSettings}">
                <p class="sidebar-bottom-text" (click)="showSaveColumnSettings()">Save Selected Columns</p>
                <div class="sidebar-bottom-buttons">
                    <button pButton pRipple type="button" label="Clear" class="p-button-outlined"
                        (click)="clearSelectedColumns()"></button>
                    <button pButton pRipple type="button" label="Apply" (click)="applySelectedColumns()"></button>
                </div>
            </div>
            <div class="sidebar-saveSettings" [ngClass]="{'hidden' : !displaySaveColumnSettings}">
                <em class="pi pi-times" (click)="displaySaveColumnSettings = false"></em>
                <form class="p-inputgroup" [formGroup]="columnSettings" (ngSubmit)="saveColumnSettings()">
                    <input type="text" pInputText placeholder="Enter Filter Name" formControlName="columnName">
                    <button type="submit" pButton pRipple label="Save"></button>
                </form>
            </div>
        </div>
    </p-sidebar>

    <!-- Filter Sidebar -->
    <p-sidebar class="filterSidebar" [transitionOptions]="'0ms'" [(visible)]="showFilterSidebar"
        [style]="{ padding: '0'}" [modal]="false" [baseZIndex]="0" ariaCloseLabel="Filter">
        <p class="sidebar-title">Filter</p>
        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input type="text" pInputText placeholder="Search">
        </span>
    </p-sidebar>

    <!-- Candidate Details Sidebar -->
    <div *ngIf="displayUserDetails">
        <p-sidebar class="candidateDetaile" [transitionOptions]="'0ms'" [(visible)]="displayUserDetails"
            styleClass="p-sidebar-lg" position="right" [style]="{width: '921px'}">
            <app-candidate-info [disableActivity]="false" [selectedUser]="selectedUser" [numberOfStages]="numberOfStages"></app-candidate-info>
        </p-sidebar>
    </div>

    <!-- Download Sidebar -->
    <p-sidebar [transitionOptions]="'0ms'" class="downloadSidebar candidateListDownload" [modal]="false"
        [style]="{ padding: '0', border: '2px solid white'  }" [(visible)]="showDownloadSidebar">
        <p class="sidebar-title">Download Data</p>

        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input pInputText (ngModelChange)="searchColumns($event)" [(ngModel)]="downloadSearchTerm" placeholder="Search"
                type="text" />
        </span>

        <div *ngFor="let col of colsToDownload" class="field-checkbox">
            <p-checkbox name="category" [value]="col" [(ngModel)]="checkedColumns" [inputId]="col.field"></p-checkbox>
            <label [for]="col.field" [style]="{paddingLeft: '10px'}">{{col.header}}</label>
        </div>

        <div class="download-container">
            <p>Download as</p>
            <div class="radioButton-group">
                <div class="radioButton">
                    <p-radioButton [(ngModel)]="downloadAs" value="excel" name="exportButtons" inputId="excel">
                    </p-radioButton>
                    <label [style]="{color: downloadAs === 'excel' ? 'var(--fc-primary)' : 'var(--fc-light-dark)'}"
                        for="excel"><em class="pi pi-file"></em>Excel</label>
                </div>
                <div class="radioButton">
                    <p-radioButton [(ngModel)]="downloadAs" name="exportButtons" inputId="csv" value="csv">
                    </p-radioButton>
                    <label [style]="{color: downloadAs === 'csv' ? 'var(--fc-primary)' : 'var(--fc-light-dark)'}"
                        for="csv"><em class="pi pi-file-excel"></em>CSV</label>
                </div>
            </div>

            <button class="p-button-raised" pRipple pButton type="button"
                (click)="(downloadAs === 'excel') ? exportCandidate('excel') : exportCandidate('csv')" label="Download"></button>
        </div>

    </p-sidebar>

    <!-- Table -->
    <p-table #dt [columns]="_selectedColumns" [value]="candidateList" [loading]="loadingSpinner" [scrollable]="true"
        scrollHeight="76vh" scrollDirection="both" responsiveLayout="scroll" [resizableColumns]="true" [rows]="pageSize"
        dataKey="id" [exportFilename]="exportName" [globalFilterFields]="['userName', 'status', 'stage', 'email']">

        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="table-header-left">
                    <button pButton title="Settings"
                        (click)="showSettingsSidebar = true; showFilterSidebar = false; showDownloadSidebar = false"
                        class="p-button-text simple-btn{{showSettingsSidebar ? '-active' : '' }}"
                        icon="pi pi-sliders-h">
                    </button>
                    <!-- <button pButton title="Filter"
                        (click)="showFilterSidebar = true; showSettingsSidebar = false; showDownloadSidebar = false"
                        class="p-button-text simple-btn{{showFilterSidebar ? '-active' : '' }}" icon="pi pi-filter">
                    </button> -->
                    <button pButton title="Download"
                        (click)="showDownloadSidebar = true; showSettingsSidebar = false; showFilterSidebar = false"
                        class="p-button-text simple-btn{{showDownloadSidebar ? '-active' : '' }}" icon="pi pi-download">
                    </button>
                    <button pButton pRipple type="button" icon="pi pi-refresh" (click)="insideSearch ? getNavigationSearch() : getUsers()" title="Refresh"
                        class="p-button-text simple-btn{{loadingSpinner ? '-active' : '' }}"></button>
                    <p-divider layout="vertical"></p-divider>
                    <label>Total List: <span [style]="{color: '#333333'}">{{ totalLength }}</span></label>
                </span>
                <span class="table-menu">
                    <p [style]="{paddingRight: '10px'}">View</p>
                    <p-dropdown [options]="pageLimits" [(ngModel)]="pageSize"
                        [style]="{ maxWidth: '5rem', fontSize: '0.9rem' }" (onChange)="getUsersByLimit()">
                    </p-dropdown>
                    <p-divider layout="vertical"></p-divider>
                    <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                        {{ first }} to {{ last }}
                    </button>
                    <button pButton class="p-button-secondary p-button-text simple-btn" (click)="prev()"
                        [disabled]="isFirstPage()" icon="pi pi-angle-left"></button>
                    <button pButton class="p-button-secondary p-button-text simple-btn" (click)="next()"
                        [disabled]="isLastPage()" icon="pi pi-angle-right"></button>
                </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr class="table-heading"
                [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}">
                <th pFrozenColumn scope="col" [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th [style]="{width: '15vw'}" scope="col" pSortableColumn="id" pResizableColumn pFrozenColumn>
                    Candidate ID
                    <p-sortIcon class="sort-icon" field="id"></p-sortIcon>
                </th>
                <th [style]="{width: '15vw'}" scope="col" pSortableColumn="name" pResizableColumn pFrozenColumn>
                    Name
                    <p-sortIcon class="sort-icon" field="name"></p-sortIcon>
                </th>
                <th *ngFor="let user of columns" pResizableColumn [style]="{width: '13vw'}" scope="col"
                    pSortableColumn="{{ user.field }}">
                    {{ user.header }}
                    <p-sortIcon class="sort-icon" field="{{ user.field }}"></p-sortIcon>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-user let-columns="columns">
            <tr class="row-candidate-list"
                [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}"
                [style]="{cursor: 'pointer'}">
                <td [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
                    <p-tableCheckbox [value]="user"></p-tableCheckbox>
                </td>
                <td [style]="{width: '15vw'}" pFrozenColumn (click)="showUserDetails(user)">{{ user.id }}</td>
                <td [ngClass]="{'capitalize': true}" [style]="{width: '15vw'}" pFrozenColumn (click)="showUserDetails(user)">{{ user.name }}</td>
                <td *ngFor="let col of columns" [style]="{width: '13vw'}" (click)="showUserDetails(user)">

                    <span [ngSwitch]="true">
                        <span class="status-tag {{ user[col.field] }}-tag" *ngSwitchCase="col.field === 'status'">
                            {{candidateStatus[user[col.field]]}}
                        </span>

                        <span [style]="{fontSize: '12px'}" *ngSwitchCase="col.field === 'stageId'">

                            <div [ngSwitch]="user['status']">
                                <span *ngFor="let status of statusColor">
                                    <span *ngSwitchCase="status.status">
                                        <round-progress class="round-progress" [radius]="32" [stroke]="6"
                                            [background]="'#DFE3E7'" [current]="user._stageId" [max]="numberOfStages"
                                            [rounded]="true" [semicircle]="true" [color]="status.color">
                                        </round-progress>
                                    </span>
                                </span>
                            </div>

                            <div [style]="{color: 'black', margin: '-15px 0 0 22px'}">
                                {{user._stageId}}/{{numberOfStages}}
                            </div>
                        </span>

                        <span *ngSwitchCase="col.field === 'source'">
                            <em *ngIf="user[col.field]" class="pi pi-{{user[col.field].toLowerCase()}}"
                                [style]="{padding: '5px 5px 0'}"></em>{{user[col.field]}}
                        </span>

                        <span *ngSwitchCase="col.field === 'createdAt' || col.field == 'dob'">
                            {{ user[col.field] | date: dateFormat || '-' }}
                        </span>

                        <span [ngClass]="{'capitalize': col.isCapitalize}" *ngSwitchDefault>
                            {{user[col.field] ? user[col.field] : '-'}}
                        </span>

                    </span>

                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="{{displayJobDetails ? 'jobDetails-page' : 'hidden'}}">
    <app-event-details *ngIf="isInEvent"></app-event-details>
    <app-job-details *ngIf="!isInEvent"></app-job-details>
</div>

<div class="{{displayWorkflow ? 'workflow-page' : 'hidden'}}">
    <app-workflow (stagesEmitter)="stageDetails = $event; numberOfStages = $event.length"></app-workflow>
</div>
<div class="{{displayInterview ? 'interview-page' : 'hidden'}}">
    <app-interview-panel></app-interview-panel>
</div>