import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;


  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const jobId = window.localStorage.getItem('selectedJobId') || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      Authorization: localStorage.getItem('token') ?? '', 
      schoolid: schoolId,
      jobId: jobId,
    });
    return { headers };
  }

  constructor(private http: HttpClient) { }

  //Company API
  getCompanyData(): Observable<any> {
    return this.http.get(this.api_url + '/company-details/', this.options());
  }
  updateCompanyData(id: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/company-details/' + id ,payload, this.options());
  }

  //Primary API
  updateAccount(payload: any): Observable<any>{
    return this.http.put(this.api_url + '/primaryAccount/update/details',payload, this.options());
  }

  //Branch API
  getBranchData(): Observable<any> {
    return this.http.get(this.api_url + '/company-branches/', this.options());
  }

  updateBranchData(id: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/company-branches/' + id ,payload, this.options());
  }

  //Departments API
  getAllDepartmentData(): Observable<any> {
    return this.http.get(this.api_url + '/company-departments/', this.options());
  }

  getDepartmentData(id: string): Observable<any> {
    return this.http.get(this.api_url + '/company-departments/' + id, this.options());
  }

  updateDepartmentData(id: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/company-departments/' + id ,payload, this.options());
  }

  deleteDepartmentData(id: string): Observable<any> {
    return this.http.delete(this.api_url + '/company-departments/' + id, this.options());
  }

  addDepartmentData(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/company-departments/', payload, this.options());
  }

  dateTimeUpdate(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/schools/date-settings' , payload, this.options());
  }

  //Email
  currentEmail(userId: any, oldEmailId: any): Observable<any> {
    return this.http.post(this.api_url + '/change-email/' + userId, oldEmailId, this.options());
  }

  currentEmailVerify(payload: any):Observable<any> {
    return this.http.put(this.api_url + '/change-email/verify/', payload, this.options());
  }

  newEmail(userId: any, newEmailId: any): Observable<any> {
    return this.http.put(this.api_url + '/change-email/newmail/' + userId, newEmailId, this.options());
  }

  newEmailVerify(payload: any, userId: any): Observable<any> {
    return this.http.put(this.api_url + '/change-email/newOTP/' + userId, payload, this.options());
  }

  otpAuthentication(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/schools/otp-settings', payload, this.options());
  }
}
