import { Component, OnChanges, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { LoginService } from '../services/login.service';
import { GlobalServicesService } from '../services/global-services.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnChanges {
  currentPage: string = '';
  @Input() searchItem: any
  userDetails: any;
  userName: string = '-';
  activeTab: string ='All';
  mouseHover: boolean = false;
  showSearchBar: boolean = false;
  isLoading: boolean = false;
  searchText: string = '';
  constructor(private _router: Router, private loginService: LoginService, private globalService: GlobalServicesService) {
    this.userDetails = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails') || '') : '';
    if (this.userDetails.name) {
      this.userName = this.userDetails.name
    }
  }

  ngOnChanges(): void {
    if(this.searchItem.length)
    {
      this.showSearchBar = true;
    }
    else
    {
      this.showSearchBar = false;
    }
    this.searchFunction()
  }


  signOut = () => {
    this.loginService.logout().subscribe((response) => {
      if (response.status === 200) {
        this.removeKeys();
        this.loginService.validToken.next({
          status: response.status,
          statusMessage: 'loggedOut',
          // message : {
          //   severity: 'success',
          //   summary: 'Success',
          //   detail: 'Logged out successfully',
          // }
        })
      }
      else {
        this.loginService.validToken.next({
          status: response.status,
          message: {
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong!',
          }
        })
      }
    })
  }
  removeKeys() {
    for (var attr in localStorage) {
      if (attr != 'schoolData' && attr != 'schoolDetails')
        localStorage.removeItem(attr);
    }
  }

  searchList(event: any) {
    let value = event.target.value;
    if(value)
    {
      this.globalService.searchTerm = value;
    }
    else
    {
      this.globalService.searchTerm = '';
    }
    this.isLoading = true;
    let searchTerm = this.globalService.searchTerm;
    if(this.searchItem === 'Events'){
      this.globalService.getSearchEvents(searchTerm).subscribe((res)=>{
        this.isLoading = false;
        this.globalService.searchList.next(res);
      })
    }else if(this.searchItem === 'Candidate'){
      let payload:any = {};
      let searchedEmail = searchTerm.trim().split(/\s+/);
      searchTerm = '';
      if(searchedEmail && searchedEmail.length === 1)
      {
        searchTerm = searchedEmail[0];
      }  
      else
      {
        payload.search = searchedEmail;
      }
      this.globalService.getSearchCandidates(searchTerm, payload).subscribe(res => {
        this.isLoading = false;
        this.globalService.searchCandidateList.next(res);
      });
    }
  }

  searchFunction(){
    this.searchText = '';
    this.globalService.searchTerm = '';
  }

  searchTermUpdate()
  {
    let value = this.searchText;
    if(value.length === 0)
    {
      this.globalService.searchTerm = '';
    }
  }

  // showAll(){

  // }

  // showEvents(){
    
  // }

  // errorFileDownload(){
    
  // }
}

