import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EventServicesService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  bulk_invite_url = environment.HOST.BULK_INVITE;
  userId = localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails') || '').id
    : '';

  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const jobId = window.localStorage.getItem('selectedJobId') || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      Authorization: localStorage.getItem('token') ?? '',
      schoolid: schoolId,
      jobId: jobId,
    });
    return { headers };
  }

  constructor(private http: HttpClient) { }

  // EVENT APIs
  getAllEvents(pageNumber?: number, pageSize?: number, userRole?: string): Observable<any> {
    if (userRole !== 'admin' && userRole !== 'superAdmin') {
      return this.http.get(this.api_url + '/event/all/' + userRole + '/' + this.userId + '/?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
    }
    else {
      return this.http.get(this.api_url + '/event?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
    }
  }

  getEventById(eventId: string): Observable<any> {
    return this.http.get(this.api_url + '/event/' + eventId, this.options());
  }

  getEventJobById(eventId: string, jobId: string): Observable<any> {
    return this.http.get(this.api_url + '/event/' + eventId + '/' + jobId, this.options());
  }

  getCandidateListByEventId(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interviewLobby/getCandidatesByEventId', payload, this.options());
  }

  getUserByEmail(email: string, eventId?: string): Observable<any> {
    return this.http.get(this.api_url + '/users/' + email + '?jobId=' + eventId, this.options());
  }

  getUserById(id: string): Observable<any> {
    return this.http.get(this.api_url + '/users/candidate/particular/' + id, this.options());
  }

  getUserByIdNoAuth(id: string): Observable<any> {
    return this.http.get(this.api_url + '/users/user/particular/' + id, this.options());
  }

  getCompletedCandidateListByEventId(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interviewLobby/getCompletedCandidateListByEventId', payload, this.options());
  }

  getJitsiToken(userName: string, roomName: string, isCandidate: boolean, school_Id?: string, avatarUrl?: string): Observable<any> {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const data = {
      "roomName": roomName,
      "user_id": userName,
      "school_id": schoolId ? schoolId : school_Id,
      "isCandidate": isCandidate,
      "avatar": avatarUrl
    }
    return this.http.post(this.host_api_url + '/neohire/jititoken', data);
  }

  sendMessage(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/jobs', payload, this.options());
  }

  getFilterEvents(pageNumber?: number, pageSize?: number, status?: string): Observable<any> {
    return this.http.get(this.api_url + '/event?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&status=' + status, this.options());
  }

  createEvent(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/event/', payload, this.options());
  }

  cloneEvent(payload: any, eventId: string): Observable<any> {
    return this.http.post(this.api_url + '/event/create/clone/' + eventId, payload, this.options());
  }

  updateEvent(payload: any, eventId: string): Observable<any> {
    return this.http.put(this.api_url + /event/ + eventId, payload, this.options());
  }

  deleteEvent(eventId: string): Observable<any> {
    return this.http.delete(this.api_url + '/event/' + eventId, this.options());
  }

  getSchool(): Observable<any> {
    return this.http.get(this.api_url + '/schools', this.options());
  }

  // COLUMN APIs
  saveColumns(schoolId: string, payload: any): Observable<any> {
    return this.http.put<any>(this.api_url + '/column/event/' + schoolId, payload, this.options());
  }

  updateColumns(schoolId: string, columnId: string, payload: Array<any>): Observable<any> {
    return this.http.put(this.api_url + '/column/event/' + schoolId + '/' + columnId, payload, this.options());
  }

  deleteColumns(schoolId: string, columnId: string): Observable<any> {
    return this.http.delete(this.api_url + '/column/event/' + schoolId + '/' + columnId, this.options());
  }

  saveColumnCandidate(jobId: string, payload: Array<any>, eventId: string): Observable<any> {
    return this.http.put<any>(this.api_url + '/jobs/column/' + jobId + '?eventJobId=' + eventId, payload, this.options());
  }

  deleteColumnCandidates(jobId: string, columnId: string, eventId: string): Observable<any> {
    return this.http.delete(this.api_url + '/jobs/column/' + jobId + '?eventJobId=' + eventId + '/' + columnId, this.options());
  }

  getAllUser(pageNumber: number = 0, pageSize: number = 0): Observable<any> {
    return this.http.get(this.api_url + '/users/?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
  }

  getAllRoles(): Observable<any> {
    return this.http.get(this.api_url + '/roles/list/job/roles', this.options());
  }

  getEventDetails(): Observable<any> {
    return this.http.get(this.api_url + '/event/all/list/events', this.options());
  }

  getRoleById(eventId: string): Observable<any> {
    return this.http.get(this.api_url + '/roles/' + eventId, this.options());
  }

  createCandidate(payload: any, eventJobId: string, eventTitle: string): Observable<any> {
    return this.http.post(this.api_url + '/users/addCandidate/?eventJobId=' + eventJobId + '&eventTitle=' + eventTitle, payload, this.options());
  }

  // stageCandidate
  getAllCandidatesByStage(jobId: string, stageId: number): Observable<any> {
    return this.http.get(this.api_url + '/users/stages/' + jobId + '/' + stageId, this.options());
  }

  getCandidatesByStage(jobId: string, stageId: number, pageNumber: number = 0, pageSize: number = 0): Observable<any> {
    return this.http.get(this.api_url + '/users/page/' + jobId + '/' + stageId + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
  }

  candidateMoveStage(jobId: string, payload: any): Observable<any> {
    return this.http.post(this.api_url + '/stages/' + jobId + '/moveStage', payload, this.options());
  }

  multiCandidateMoveStage(jobId: string, payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/' + jobId + '/moveStage', payload, this.options());
  }

  candidteBulkMoveStage(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/import/file/moveStage', payload, this.options())
  }

  candidateChangeStatus(jobId: string, actionId: string, payload: any): Observable<any> {
    return this.http.post(this.api_url + '/stages/' + jobId + '/' + actionId, payload, this.options());
  }

  multipleCandidateChangeStatus(jobId: string, actionId: string, payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/' + jobId + '/' + actionId, payload, this.options());
  }
  addCandidateEntry(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interviewLobby', payload)
  }

  getSpcCandidateEntry(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interviewLobby/getSpcCandidateEntry', payload)
  }

  sendMessageWS(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interviewLobby/sendMessageWS', payload, this.options())
  }

  updateInterviewStatus(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interviewLobby/update/interviewStatus', payload)
  }

  getRecruiterListByEvent(eventId: string): Observable<any> {
    return this.http.get(this.api_url + '/event/a/b/getRecruiterListByEvent/' + eventId, this.options())
  }

  sendInterviewLink(jobId: string, stageId: string, payload: any): Observable<any> {
    return this.http.post(this.api_url + '/interview/' + jobId + '/' + stageId, payload, this.options());
  }

  getSearchWiseCandidate(payload: any, stageId: string, jobId: string, searchTerm: string, pageNumber: number, pageSize: number) {
    return this.http.post(this.api_url + `/users/search/page/${jobId}/${stageId}?pageSize=${pageSize}&searchTerm=${searchTerm}&pageNumber=${pageNumber}`, payload, this.options());
  }

  checkIfUserInLobby(eventId: string, userId: string): Observable<any> {
    return this.http.get(this.api_url + '/interviewLobby/checkIfUserInLobby/' + eventId + '/' + userId, this.options());
  }

  sendTestLink(payload: any, eventId: string, stageId: string, eventCategory: string): Observable<any> {
    return this.http.post(this.api_url + '/sendTestLink/' + eventId + '/' + stageId + '/' + eventCategory, payload, this.options());
  }

  sendTestLinkToAll(eventId: string, stageId: string, eventCategory: string, payload: any, stageNumber: number): Observable<any> {
    return this.http.post(`${this.api_url}/stages/allcandidates/${eventId}/${stageId}/${eventCategory}?stageNumber=${stageNumber}`, payload, this.options());
  }

  getStage(jobId?: string, stageId?: string): Observable<any> {
    return this.http.get(this.api_url + '/stages/' + jobId + '/' + stageId, this.options());
  }

  getParticularStage(jobId?: string, stageId?: string): Observable<any> {
    return this.http.get(this.api_url + '/stages/value/' + jobId + '/' + stageId, this.options());
  }

  getUserUnAuth(userId: string): Observable<any> {
    return this.http.get(this.api_url + '/interview/candidate/' + userId, this.options());
  }

  getCandiateStageActivity(eventId: string, userId: string): Observable<any> {
    return this.http.get(this.api_url + '/stage-user-mapping/' + eventId + '/' + userId, this.options());
  }

  getCandiateStatusActivity(eventId: string, userId: string): Observable<any> {
    return this.http.get(this.api_url + '/status-user-mapping/' + eventId + '/' + userId, this.options());
  }

  getJobUserMapping(eventId: string, userId: string): Observable<any> {
    return this.http.get(this.api_url + `/job-user-mapping/${eventId}/${userId}`, this.options());
  }

  //Stage Candidates API
  getScheduleDownloadTemplate(eventId: string, stageId: string, type: string): Observable<any> {
    return this.http.get(this.bulk_invite_url + '/cloudRun/download?stageId=' + stageId + '&eventId=' + eventId + '&type=' + type, this.options());
  }

  postScheduleUpload(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/bulk/file/send-interview-email/', payload, this.options());
  }

  sendVettedPooleMail(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/email-template/vettedPool', payload, this.options());
  }

  getAllCohortId(): Observable<any> {
    return this.http.get(this.api_url + '/cohortLabel/getAllCohortLabels', this.options());
  }

  getAllCohortPagination(pageNumber: any, pageSize: any): Observable<any> {
    return this.http.get(this.api_url + '/cohortLabel/getCohorts?pageNumber=' + pageNumber + '&pageSize=' + pageSize, this.options());
  }

  createCohortId(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/cohortLabel/add', payload, this.options());
  }

  updateCohortData(payload: any): Observable<any> {
    return this.http.put(this.api_url + '/cohortLabel/update', payload, this.options());
  }

  mapCohortId(eventId: string, payload: any) {
    return this.http.post(this.api_url + '/cohortLabel/mapCohort/' + eventId, payload, this.options());
  }

  //Bulk Upload
  sendBulkInvite(payload: any, actionId: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/event/registration/invite/' + actionId, payload, this.options());
  }

  sendSingleInvite(payload: any, actionId: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/event/registration/invite/' + actionId, payload, this.options());
  }

  getUserDetailsByJobUserId(jobUserId: string): Observable<any> {
    return this.http.get(this.api_url + '/job-user-mapping/getUserDetailsById/' + jobUserId, this.options());
  }

  //Cohort APIs
  getCohortDownload(eventId: any, stageId: any): Observable<any> {
    return this.http.get(this.api_url + '/users/cohort/' + eventId + '/' + stageId, this.options());
  }

  getMultipleCohortDownload(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/users/multiSelect/download', payload, this.options());
  }

  postCohortUpload(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/cohort/upload', payload, this.options());
  }

  getAllTestScoreByEmail(jobId: string, email: string): Observable<any> {
    return this.http.get(this.api_url + '/test/' + `${jobId}/${email}`, this.options());
  }

  getFeedbackDownloadLink(eventId: string, userId: string, stageId: number) {
    return this.http.get(this.bulk_invite_url + '/cloudRun/downloadFeedback/' + `${eventId}/${userId}/${stageId}`, this.options());
  }

  //Workflow Cohort
  uploadCohortFile(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/cohort/upload', payload, this.options());
  }

  sendOfferCohort(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/bulk-cohort/send-offer/', payload, this.options());
  }

  downloadGenericCandidates(downloadName: string): Observable<any> {
    return this.http.get(this.bulk_invite_url + '/bulk-cohort/' + downloadName, this.options());
  }

  downloadAllCandidates(downloadName: string, jobId: any, stageId: any): Observable<any> {
    return this.http.get(this.bulk_invite_url + '/bulk-cohort/' + downloadName + '/?jobId=' + jobId + '&stageId=' + stageId, this.options());
  }

  downloadSelectedCandidates(downloadName: string, payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/bulk-cohort/' + downloadName, payload, this.options());
  }

  //Interview APIs
  getInterviewDetails(eventId: any, emailId: string): Observable<any> {
    return this.http.get(this.api_url + '/interview/allDetails/' + eventId + '/' + emailId, this.options());
  }

  //Candidate Events
  eventCandidateSearch(eventId: any, pageSize: any, pageNumber: any, payload: any): Observable<any> {
    return this.http.post(this.api_url + '/users/search/' + eventId + '/candidate/?pageSize=' + pageSize + '&pageNumber=' + pageNumber, payload, this.options());
  }

  eventTemplateDownload(): Observable<any> {
    return this.http.get(this.bulk_invite_url + '/cloudRun/downloadEventBulkTemplate', this.options());
  }

  eventBulkUpload(payload: any): Observable<any> {
    return this.http.post(this.bulk_invite_url + '/cloudRun/event/bulk/invite', payload, this.options());
  }

}
