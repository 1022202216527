import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { JobServicesService } from 'src/app/services/job-services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-workflow-manage-schedule',
  templateUrl: './workflow-manage-schedule.component.html',
  styleUrls: ['./workflow-manage-schedule.component.css'],
  providers: [MessageService]
})
export class WorkflowManageScheduleComponent implements OnInit {

  @Input() stage: any;

  @Output() isTestSelected = new EventEmitter<any>();
  @Output() isFeedbackSelected = new EventEmitter<any>();
  @Output() emitTestId = new EventEmitter<any>();
  @Output() emitContestId = new EventEmitter<any>();

  showSummary: boolean = false;
  isInEvent: boolean = false;
  // disabled: boolean = true;

  contestList: Array<any> = [];
  formsList: Array<any> = [];
  savedSchedule: any;
  selectedContest: any = null;
  selectedForm: any = null;
  userPrivileges: any;

  scheduledBy: string = '';
  testSource: string = '';
  timelimit: number = 0;

  testId: string = '';
  contestName: string = '';
  testName: string = '';
  formName: string = '';
  jobId: string = '';
  eventId: string = ''
  eventName: string = '';
  jobName: string = '';
  schoolCode: string = '';
  testDuration: string = '';

  constructor(private jobServices: JobServicesService, private router: Router, private messageService: MessageService, private globalService: GlobalServicesService) {
    this.schoolCode = localStorage.getItem('schoolData')
    ? JSON.parse(localStorage.getItem('schoolData') || '').school_code
    : '';
    let feedbackTypeData: any = environment.FEEDBACK;
    this.formsList = [
      { interviewType:  feedbackTypeData[this.schoolCode]['TECHNICAL'], feedbackType: "technical" },
      { interviewType: feedbackTypeData[this.schoolCode]['FINAL'], feedbackType: "final" }
    ]
  }

  ngOnInit() {
    this.jobId = window.localStorage.getItem("selectedJobId") || '';
    this.eventId = window.localStorage.getItem("selectedEventId") || '';
    this.jobName = window.localStorage.getItem("selectedJobName") || '';
    this.eventName = window.localStorage.getItem("selectedEventName") || '';
    this.isInEvent = this.jobId === this.eventId;
    this.userPrivileges = JSON.parse(this.globalService.decryptPrivileges()).userPrivileges;
    this.getAnaytics();
  }

  getAnaytics = () => {
    this.jobServices.getStage(this.jobId, this.stage.id).subscribe(res => {
      let stage = res?.data ? res.data : {};
      // if (stage?.scheduleTest)
      this.savedSchedule = stage?.scheduleTest ? stage.scheduleTest : {};
      this.selectedForm = stage?.scheduleInterview ? stage.scheduleInterview : {};

      this.contestList = []
      this.jobServices.getWorkflowContests().subscribe(list => {
        this.contestList = list.data ? list.data.data : [];
        this.contestList.forEach(element => {
          if (element.c_id == this.savedSchedule.courseName) {
            this.contestName = element.name;
            this.selectedContest = element;
            this.getContestTestId();
          }
        });
      })

      if (stage.scheduleTest) {
        this.showSummary = true;
        this.testSource = this.savedSchedule.courseName;
        this.scheduledBy = this.savedSchedule.scheduledBy;
        this.timelimit = this.savedSchedule.timelimit;
        this.isTestSelected.emit(true);
        this.emitTestId.emit(this.savedSchedule.testSchedule);
        this.emitContestId.emit(this.savedSchedule.courseName);
      }
      else if (stage.scheduleInterview) {
        this.showSummary = true;
        this.formName = this.selectedForm.interviewType;
        this.isFeedbackSelected.emit(true);
      }
      else {
        this.isTestSelected.emit(false);
        this.isFeedbackSelected.emit(false);
      };

    })
  }

  getContestTestId() {
    let selected_cid = this.selectedContest.c_id;
    this.jobServices.getContestTriggerId(selected_cid).subscribe(res => {
      if (Object.keys(res.data).length) { this.testId = res.data.t_id; this.testName = res.data.t_name; this.testDuration = res.data.testDuration }
      else this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to get Contest!' });
    });
  }

  clearTestAndSchedules = () => {
    if (this.stage.type == 'Test') {
      this.isTestSelected.emit(false);
      this.selectedContest = null;
    } else if (this.stage.type == 'Interview') {
      this.isFeedbackSelected.emit(false);
      this.selectedForm = null;
    }
  }

  saveTestAndSchedules = () => {
    this.showSummary = true;
    let payload = {
      courseName: this.selectedContest.c_id,
      testSchedule: this.testId,
      scheduledBy: "manually",
      timelimit: 2,
      testName: this.testName,
      contestName: this.selectedContest.name,
      scheduleTime: this.selectedContest.schedule,
      testDuration: this.testDuration
    }

    this.jobServices.updateStageSchedule(this.jobId, this.stage.id, payload).subscribe((res) => {
      this.savedSchedule = res?.data ? res.data?.scheduleTest : [];
      this.contestName = this.selectedContest.name;
      this.isTestSelected.emit(true);
      this.emitTestId.emit(this.testId);
      this.emitContestId.emit(this.selectedContest.c_id);
    })
  }

  saveFeedbackForm = () => {
    this.showSummary = true;
    let payload = this.selectedForm;
    this.formName = '';

    this.jobServices.updateFeedbackForm(this.jobId, this.stage.id, payload).subscribe(res => {
      if (res.data?.scheduleInterview) { this.formName = res.data?.scheduleInterview.interviewType }
      this.isFeedbackSelected.emit(true);
    })
  }

  openSlots = () => {
    this.router.navigate(
      [`${this.isInEvent ? this.eventName : this.jobName}/${this.stage.name}/manage-slot`],
      { queryParams: { stageName: this.stage.name, stageId: this.stage.id } }
    )
  }

}
