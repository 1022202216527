<div style="display:flex; justify-content:center; flex-direction: column;">
    <div *ngIf="!onlyFeedbackView" class="profileCard-container" style="max-width: 1140px; margin-bottom: 1vh;">
        <div class="profileImg">
            <!-- <img src="https://ediindia.ac.in/alumni/web/profile.jpg" alt="profile"> -->
            <img src="{{ candidateDetails?.profilePic ? signedProfileUrl : 'https://ediindia.ac.in/alumni/web/profile.jpg'}}"
                alt="profile">
        </div>
        <div class="userDetails">
            <p class="userName">{{candidateDetails?.name}}</p>
            <p-skeleton width="6rem" height="1.5rem" [style]="{margin: '-20px 0 15px 20px'}" style styleClass="mb-2"
                *ngIf="!candidateDetails?.name"></p-skeleton>
            <div class="userInfo">
                <p class="userInfo-text"><em class="pi pi-id-card"></em> {{candidateDetails?.id}}</p>
                <p-skeleton width="6rem" height="1.2rem" styleClass="mb-2" *ngIf="!candidateDetails?.id"></p-skeleton>
                <span class="divider"></span>
                <p class="userInfo-text"><em class="pi pi-at"></em> {{candidateDetails?.email}}</p>
                <p-skeleton width="6rem" height="1.2rem" styleClass="mb-2"
                    *ngIf="!candidateDetails?.email"></p-skeleton>
                <span class="divider"></span>
                <p class="userInfo-text" *ngIf="candidateDetails?.mobile_number"><em class="pi pi-phone"></em> +91
                    {{candidateDetails?.mobile_number}}</p>
                <p-skeleton width="6rem" height="1.2rem" styleClass="mb-2"
                    *ngIf="!candidateDetails?.mobile_number"></p-skeleton>
            </div>
        </div>
    </div>
    <div>

        <div *ngIf="!onlyFeedbackView" class="header-tabs">
            <button class="tabs" [ngClass]="{'selected': displayFeedback}"
                (click)=" displayFeedback = true; displayResume = false">Feedback</button>
            <button class="tabs" [ngClass]="{'selected': displayResume}"
                (click)=" displayFeedback = false; displayResume = true">Resume</button>
        </div>

        <div class="feedbackForm-container">
            <div class="form-data" *ngIf="displayFeedback && !onlyFeedbackView">
                <div class="feedback-provider" *ngIf="(inViewMode || inEditMode)  && !isLoading">
                    <div>
                        <p-avatar class="avatar-align"
                        image="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"></p-avatar>
                    </div>
                    <div class="feedback-provider_details feedback-provider_pic"><em class="pi pi-id-card"></em> {{interrogatorDetails?.interrogatorName ? interrogatorDetails?.interrogatorName : '-'}}</div>
                    <div class="feedback-provider_date"><em class="pi pi-calendar"></em>{{interrogatorDetails?.updatedAt | date:'longDate' || '-'}}</div>
                </div>
                <div class="form-details_header">
                    <h2 class="form-title" #title>{{formName}}</h2>
                </div>
            </div>

            <div *ngIf="isLoading" class="loadingFeedback">
                <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
                </p-progressSpinner>
            </div>
            <div [ngClass]="{'hidden' : inViewMode || isLoading || displayResume}">
                <div *ngFor="let section of templateCollections; index as i">
                    <div class="personal-info">
                        <div *ngIf="i != 0">
                            <h3 class="personal-details_label">{{section[0].displaySection}}</h3>
                            <hr class="title-line">
                        </div>
                        <div *ngFor="let model of section">
                            <app-fields [model]="model" [clicked]="clicked" (checkValidator)="checkValid($event, model)"
                                (fieldDataEmitter)="bindData($event, model)" [invalidField]="invalidField"
                                [inEditMode]="inEditMode" *ngIf="checkForDependancy(model)" [ansChange]="ansChange">
                            </app-fields>
                        </div>
                    </div>
                </div>
            </div>

            <div class="feedbackPreview-container" *ngIf="inViewMode && displayFeedback && !isLoading">
                <div class="feedback-options" [style]="{justifyContent: 'end'}">
                    <p class="link" (click)="downloadFeedback()"><i class="pi pi-download"></i> Download Feedback</p>
                </div>
                <div *ngFor="let feedback of feedbackCollections; index as i">
                    <div *ngIf="i != 0" [style]="{width: '90%', margin: '0 auto'}">
                        <h3 class="personal-details_label">{{ feedback.field[0].displaySection}}</h3>
                        <!-- <hr class="title-line">  -->
                    </div>
                    <div *ngFor="let field of feedback.field">
                        <div class="feedbackPreview" *ngIf="field.fieldType != 'none'">
                            <span class="feedback-field question">{{field.fieldName}}</span>
                            <span class="feedback-field seperator">:</span>
                            <span class="feedback-field answer" [ngSwitch]="true">
                                <span *ngSwitchCase="field.answer[0] === true">Yes</span>
                                <span *ngSwitchCase="field.answer[0] === 'true'">Yes</span>
                                <span *ngSwitchCase="field.answer[0] === false">No</span>
                                <span *ngSwitchCase="field.answer[0] === 'false'">No</span>
                                <span *ngSwitchDefault>{{field.answer?.length ? field.answer?.join(', ') :
                                    '-'}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="interview-score" *ngIf="scoreSections.includes(feedback.field[0].displaySection)">
                        <span class="total-score label">Total Sectional Score:</span>
                        <span class="total-score answer"><span>{{feedback.score}}</span>/{{feedback.totalScore}}</span>
                    </div>
                    <hr style="width: 90%; margin: 0 auto 30px;" *ngIf="(i+1) != feedbackCollections.length">
                </div>
            </div>

            <div *ngIf="!isLoading && !displayFeedback">
                <div *ngIf="candidateDetails?.resume && pdfSrc.length" class="resume-download">
                    <div (click)="downloadResume()" class="resume-download_label">
                        <span class="pi pi-download resume-download_icon"> </span>Download Resume
                    </div>
                </div>
            </div>

            <div class="feedbackPreview-container feedback-preview" *ngIf="!isLoading && !displayFeedback">
                <pdf-viewer *ngIf="candidateDetails?.resume" class="pdf-viewer" [src]="pdfSrc" [render-text]="true"
                    [fit-to-page]="true" style="width: 700px; height: 800px"></pdf-viewer>
                <span *ngIf="!candidateDetails?.resume">No Resume Uploaded</span>
            </div>

            <button pButton pRipple type="button" label="Submit" (click)="submit()" class="form-btn"
                [loading]="submitBtnLoading" *ngIf="!inViewMode && !displayResume"></button>
        </div>
    </div>
</div>
<p-toast></p-toast>