import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-date-time-settings',
  templateUrl: './date-time-settings.component.html',
  styleUrls: ['./date-time-settings.component.css']
})
export class DateTimeSettingsComponent implements OnInit {

  isLoading: boolean = false;
  saveBtnLoading: boolean = false;
  dateTimeTemplate: boolean = false;
  clicked : boolean = false;
  
  timeValue: string = '';
  dateValue: string = '';

  dateTimeDetails: FormGroup = new FormGroup({
    dateValue: new FormControl('', Validators.required),
    timeValue: new FormControl('', Validators.required),
  });

  timeList: any = [ 
    {name : '12 hours', value : 'hh:mm'}, 
    {name : '24 hours', value : 'HH:mm'}, 
  ];
  dateList: any = [ 
    {name: 'DD/MM/YYYY', value:'dd/MM/yyyy'}, 
    {name: 'DD-MM-YYYY', value:'dd-MM-yyyy'}, 
    {name: 'DD/MMM/YY', value:'dd/MMM/yy'}, 
    {name: 'DD-MMM-YY', value:'dd-MMM-yy'}, 
    {name: 'DD/MMM/YYYY', value:'dd/MMM/yyyy'}, 
    {name: 'DD-MMM-YYYY', value:'dd-MMM-yyyy'}, 
    {name: 'YYYY/MM/DD', value:'yyyy/MM/dd'},  
    {name: 'YYYY-MM-DD', value:'yyyy-MM-dd'}
  ];

  constructor(private settings: SettingsService,private messageService: MessageService, private globalService: GlobalServicesService) { }

  ngOnInit(): void {
    let dateValue = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].dateFormat : '';
    this.dateList.forEach((item:any)=>{
      if(item.value == dateValue){
        this.dateValue = item.name;
      }
    })
    let timeValue = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].timeFormat : '';
    this.timeList.forEach((item:any) => {
      if(item.value == timeValue){
        this.timeValue = item.name;
      }
    })
  }

  timeChange(field:any){
    this.timeValue = field.name; 
  }
  dateTimeUpdate(){
    this.clicked = true;
    this.saveBtnLoading = true;

    if(this.dateTimeDetails.valid){

      let payload = {
        dateFormat : this.dateValue,
        timeFormat : this.timeValue,
      }
      this.settings.dateTimeUpdate(payload).subscribe((res: any) => {
        this.saveBtnLoading = false;
        let dateFor = res.data.dateFormat;
        let timeFor = res.data.timeFormat;
        let letter = ['D', 'Y'];
        res.data.dateFormat = dateFor.toString().replace( 
          new RegExp(letter.join('|'), 'g'),(match: string) => match.toLowerCase());
        res.data.timeFormat = timeFor == '24 hours' ? 'HH:mm' : 'hh:mm';
        let schoolArray = [res.data]
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated Successfully', })
        localStorage.setItem('schoolDetails', JSON.stringify(schoolArray));
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Please Fill the required data!', });
    }
  }

}
