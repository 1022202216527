import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OtpService } from 'src/app/services/otp.service';
import { nanoid } from 'nanoid';

@Component({
  selector: 'app-login-otp-verification',
  templateUrl: './login-otp-verification.component.html',
  styleUrls: ['./login-otp-verification.component.css']
})
export class LoginOtpVerificationComponent implements OnInit {

  @ViewChild('otp_value') otp_value: any;

  @Input() loginEmail: string = '';
  @Input() routingDetails: any;
  @Input() otpRequired: any;
  @Input() userDetails: any;
  @Input() privilegeData: any;

  otpValue: any;
  userName: string = '';
  infoMessage: any = {};

  btnLoading: boolean = false;
  pageLoading: boolean = false;
  sendOtpState: boolean = true;
  verifyOtpState: boolean = true;
  waitToResend: boolean = false;
  loadResend: boolean = false;
  clicked: boolean = false;
  invalidOTP: boolean = false;
  expiredOTP: boolean = false;
  displayInfoMessage: boolean = true;
  loginCheck: boolean = true;

  otpTimer: number = 0;
  countdown: string = '';
  redirectUrl: string = '';
  loginUserName: string = ''
 
  constructor(private otpService: OtpService,private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if(this.loginCheck){
      this.pageLoading = true;
      this.otpService.getUserByEmail(this.loginEmail.toLowerCase()).subscribe((res:any) => {
        if(res.status === 200){
          this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.loginEmail, bgColor: '#55BA45' };
          // this.showInfoMessage();
          this.startOtpTimer();
          this.btnLoading = false;
          this.sendOtpState = false;
          this.verifyOtpState = true;
          this.waitToResend = true;
          this.pageLoading = false;
          this.loginUserName = res.data?.name;
        }
      })
    }
  }

  loginVerifyOtp = () => {
    this.clicked = true;
    if (this.otpValue.toString().length == 6) {
      this.btnLoading = true;
      this.invalidOTP = false;
      this.expiredOTP = false;
      let payload = { otp: Number(this.otpValue) };
      this.otpService.verifyOtp(this.loginEmail.toLowerCase(), payload).subscribe(res => {
        this.btnLoading = false;
        switch (res.data) {
          case 'success': {
              this.invalidOTP = false;
              this.clicked = false;
              let allPrivileges = this.privilegeData;
              localStorage.setItem("userPrivilege", allPrivileges);
              localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
              localStorage.setItem('token', this.routingDetails.userToken);
              this.router.navigateByUrl('/events');
          } break;
          case 'failed': {
            this.invalidOTP = true;
            this.infoMessage = { icon: 'pi pi-check', message: 'Invalid OTP, Please ', tryAgain: ' Try Again!', bgColor: '#ba4545' }
            // this.showInfoMessage();
          } break;
          case 'expired': {
            this.expiredOTP = true;
            this.infoMessage = { icon: 'pi pi-check', message: 'OTP Expired!', bgColor: '#ba4545' }
            // this.showInfoMessage();
          }
        }
      })

    } else {
      this.invalidOTP = true;
    }
  }

  resendOtp = (status: string) => {
    this.loadResend = true;
    let payload = {
      verificationType : '2FA',
    }
    this.otpService.sendOtp(this.loginEmail.toLowerCase(), nanoid(10),payload).subscribe((res:any) => {
      this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.loginEmail, bgColor: '#55BA45' }
      // this.showInfoMessage();
      this.loadResend = false;
      this.waitToResend = true;
      if(status != 'tryAgain'){
        this.startOtpTimer();
      }
    })
  }

  startOtpTimer() {
    this.otpTimer = 60;
    this.countdown = this.formatTime(this.otpTimer);
    this.waitToResend = true;
    const intervalId = setInterval(() => {
      this.otpTimer--;
      this.countdown = this.formatTime(this.otpTimer);

      if (this.otpTimer === 0) {
        clearInterval(intervalId);
        this.waitToResend = false;
      }
    }, 1000);
  }

  formatTime(seconds: number): string {
    return `${seconds.toString().padStart(2, '0')}`;
  }

  restrictNumber(event: any) {
    let e: any = event || window.event;
    let key = e.keyCode || e.which;

    if (key == 13) {
      this.loginVerifyOtp();
    }
    if (key < 48 || key > 57 || event.target.value.toString().length >= 6) {
      if (e.preventDefault) e.preventDefault();
      e.returnValue = false;
    }
  }

  // showInfoMessage = () => {
  //   this.displayInfoMessage = true;
  //   setInterval(() => {
  //     console.log('5 sec completed')
  //     // this.displayInfoMessage = false;
  //     // this.infoMessage = {};
  //   }, 10000);
  // }

  otpValueChange(event: any) {
    if (event.length == 0 || event.length == 6) {
      this.invalidOTP = false;
      this.expiredOTP = false;
    }
  }
}
