<div class="manage-candidate">
  <div *ngIf="isLoading" class="loadingOverlay">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
  </div>

  <div class="invite-candidate_container">
    <div class="status">
      <p-splitButton label="Status: {{ statusSelected }}" [model]="this.status"
          styleClass="p-button-sm mr-2 mb-2 p-button-outlined p-button-secondary"></p-splitButton>
    </div>  
    <button *ngIf="privilege.editManageCandidate" pButton pRipple class="p-button-raised" type="button"
      (click)="addTalentpoolSlider = true" label="Invite Candidate" style="width: 190px !important;"><em
        class="invite-candidate_icon pi pi-user-plus"></em></button>
  </div>
</div>
<div class="invite-slider" *ngIf="addTalentpoolSlider">
  <p-sidebar class="addTalentpool-slider" [(visible)]="addTalentpoolSlider" styleClass="p-sidebar-lg"
    [showCloseIcon]="true" position="right" (onHide)="statusSelected == 'Active' ? getAllCandidates() : getInvitedCandidates()">
    <!-- <app-talentpool-add></app-talentpool-add> -->
    <app-invite-candidate (inviteSent)="inviteSent($event)" (bulkInviteSent)="bulkInviteSent($event)"></app-invite-candidate>
  </p-sidebar> 
</div>

<p-sidebar *ngIf="sendEmailSlider" class="sendEmail-slider"  [(visible)]="sendEmailSlider" styleClass="p-sidebar-lg"
  [showCloseIcon]="true" position="right" [style]="{width: '68rem'}">
  <app-send-email [selectedUsersList]="selectedUsersList" (mailSent)="mailSent($event)" [sendMailHeader]="'Send Email'"></app-send-email>
</p-sidebar>

<div class="table">

  <!-- Filter SideBar -->
  <p-sidebar class="filterSidebar" [transitionOptions]="'0ms'" [(visible)]="showFilterSidebar"  *ngIf="statusSelected == 'Active'" (onHide)="filterHide()"
    (onShow)="filterShow()" [style]="{padding: '0', border: '2px solid white'}" [modal]="false" [baseZIndex]="0" ariaCloseLabel="Filter">
  <p class="sidebar-title">Filter</p>

  <div class="filterSidebarContent">
    <p-accordion [activeIndex]="activeTabIndex" [collapseIcon]="'pi pi-minus'" style="margin-top: 6px;">
      <p-accordionTab header='Joined Date' style="margin-top: 6px;">
        <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" [inline]="true"
          [showWeek]="true" [style]="{width: 'auto'}" inputId="range"></p-calendar>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div class="filterSidebarContent">
    <p-accordion [activeIndex]="activeTabIndex" [collapseIcon]="'pi pi-minus'">
      <p-accordionTab header='Gender'>
        <p-dropdown class="multi-talent gender-filter" [options]="genderList" [(ngModel)]="selectedGender" id="chips" [style]="{ width: '300px'}"
        display="chip" optionLabel="label" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div class="filterSidebarContent">
    <p-accordion [activeIndex]="activeTabIndex" [collapseIcon]="'pi pi-minus'">
      <p-accordionTab header='Pwd'>
        <p-dropdown class="multi-talent gender-filter" [options]="pwdList" [(ngModel)]="selectedPwd" id="chips" [style]="{ width: '300px'}"
        display="chip" optionLabel="label" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div class="filterSidebarContent">
    <p-accordion [activeIndex]="activeTabIndex" [collapseIcon]="'pi pi-minus'">
      <p-accordionTab header='Source Tag'>
        <input style="width: 300px;" placeholder="Text" type="text" pInputText [(ngModel)]="sourceTag">
      </p-accordionTab>
    </p-accordion>
  </div>
  <div class="filterSidebarContent">
    <p-accordion [activeIndex]="activeTabIndex" [collapseIcon]="'pi pi-minus'">
      <p-accordionTab header='Degree'>
        <p-multiSelect class="multi-talent" [options]="degreeList" [(ngModel)]="selectedDegree" id="chips" 
        display="chip" optionLabel="label" optionValue="value"></p-multiSelect>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div class="filterSidebarContent">
    <p-accordion [activeIndex]="activeTabIndex" [collapseIcon]="'pi pi-minus'">
      <p-accordionTab header='Branch'>
        <p-multiSelect class="multi-talent" [options]="branchList" [(ngModel)]="selectedBranch" id="chips" 
        display="chip" optionLabel="label" optionValue="value"></p-multiSelect>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div class="filterSidebarContent">
    <p-accordion [activeIndex]="activeTabIndex" [collapseIcon]="'pi pi-minus'">
      <p-accordionTab header='College'>
        <p-multiSelect class="multi-talent college-talent" [options]="collegeList" [(ngModel)]="selectedCollege" id="chips" 
        display="chip" optionLabel="label" optionValue="value"></p-multiSelect>
      </p-accordionTab>
    </p-accordion>
  </div>

  <div class="filterSidebarContent">
    <div class="sidebar-bottom">
      <div class="sidebar-bottom-apply">
        <!-- <p class="sidebar-bottom-text" (click)="displaySaveFilterSettings = true;">Save Selected Filters</p> -->
        <div class="sidebar-bottom-buttons">
          <button pButton pRipple type="button" label="Clear" class="p-button-outlined"
            (click)="clearSelectedFilters()"></button>
          <button pButton pRipple type="button" label="Apply" [loading]="filterLoading"
          [disabled]="!rangeDates[1] && sourceTag.length == 0 && selectedDegree.length == 0 && selectedBranch.length == 0 && selectedCollege.length == 0 
          && selectedGender.length == 0 && selectedPwd.length == 0"
            (click)="applySelectedFilter()"></button>
        </div>
      </div>

      <div [ngClass]="{'hidden' : !displaySaveFilterSettings}" class="sidebar-saveSettings">
        <em (click)="displaySaveFilterSettings = false" class="pi pi-times"></em>
        <div class="p-inputgroup">
          <input pInputText placeholder="Enter Filter Name" type="text">
          <button pButton pRipple label="Save" type="button"></button>
        </div>
      </div>
    </div>
    <p-accordion [multiple]="true" *ngFor="let col of _selectedColumns" [collapseIcon]="'pi pi-minus'">
      <p-accordionTab [ngSwitch]="col.field" header={{col.header}} *ngIf="col.options">
        <span *ngSwitchCase="'status'">
          <div *ngFor="let option of col.options" class="field-checkbox">
            <p-checkbox [(ngModel)]="selectedStatusElements" name="status" [inputId]="option" [value]="option">
            </p-checkbox>
            <label [for]="option">{{ option }}</label>
          </div>
        </span>

        <span *ngSwitchCase="'department'">
          <div *ngFor="let option of col.options" class="field-checkbox">
            <p-checkbox [(ngModel)]="selectedDepartmentElements" [value]="option" name="department"
              [inputId]="option"></p-checkbox>
            <label [for]="option">{{ option }}</label>
          </div>
        </span>

        <span *ngSwitchCase="'talentpoolType'">
          <div *ngFor="let option of col.options;" class="field-checkbox">
            <p-checkbox [(ngModel)]="selectedTalentpoolTypeElements" [value]="option" name="talentpoolType"
              [inputId]="option">
            </p-checkbox>
            <label [for]="option">{{ option }}</label>
          </div>
        </span>
      </p-accordionTab>
    </p-accordion>

  </div>

    <!-- <div class="filterSidebarContent">
      <p-accordion [activeIndex]="activeTabIndex" [collapseIcon]="'pi pi-minus'" style="margin-top: 6px;">
        <p-accordionTab header='Joined Date' style="margin-top: 6px;">
          <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" [inline]="true"
            [showWeek]="true" [style]="{width: 'auto'}" inputId="range"></p-calendar>
        </p-accordionTab>
      </p-accordion>
    </div> -->
    <!-- <div class="filterSidebarContent">
      <p-accordion [activeIndex]="activeTabIndex" [collapseIcon]="'pi pi-minus'">
        <p-accordionTab header='Source Tag'>
          <input style="width: 300px;" placeholder="Text" type="text" pInputText [(ngModel)]="sourceTag">
        </p-accordionTab>
      </p-accordion>
    </div> -->

  </p-sidebar>

  <!-- Column Settings Sidebar -->
  <p-sidebar class="settingsSidebar" [transitionOptions]="'0ms'" [(visible)]="showSettingsSidebar"
    [style]="{ padding: '0', border: '2px solid white'}" [modal]="false">
    <p class="sidebar-title">Column Settings</p>

    <div class="{{savedColumnSettings.length ? 'savedColumns-continer' : 'hidden'}}">
      <p>Saved Column</p>
      <div *ngFor="let column of savedColumnSettings" class="savedColumns"
        (click)="applySavedSettings(column.columnValue)">
        <p>{{column.columnName}}</p>
        <em (click)="deleteSavedColumns(column.columnId)" class="pi pi-trash"></em>
      </div>
    </div>
    <span class="p-input-icon-left">
      <em class="pi pi-search"></em>
      <input (ngModelChange)="searchColumns($event)" pInputText placeholder="Search" type="text"
        [(ngModel)]="columnSearchTerm" />
    </span>

    <div *ngFor="let col of _talentpoolCol" class="field-checkbox">
      <p-checkbox [disabled]="col.index == 1 || col.index == 2 || col.index == 3" [value]="col" name="category" [inputId]="col.field" [(ngModel)]="checkedColumns"></p-checkbox>
      <label [style]="{paddingLeft: '10px'}" [for]="col.field">{{col.header}}</label>
    </div>

    <div class="sidebar-bottom">

      <div class="{{ displaySaveColumnSettings ? 'hidden' : 'sidebar-bottom-apply'}}">
        <p (click)="displaySaveColumnSettings = true" class="sidebar-bottom-text">Save Selected Columns</p>
        <div class="sidebar-bottom-buttons">
          <button type="button" pRipple pButton class="p-button-outlined" label="Clear"
            (click)="clearSelectedColumns()"></button>
          <button pRipple pButton label="Apply" (click)="applySelectedColumns()" type="button"></button>
        </div>
      </div>

      <div [ngClass]="{'hidden': !displaySaveColumnSettings}" class="sidebar-saveSettings">
        <em (click)="displaySaveColumnSettings = false" class="pi pi-times"></em>
        <form [formGroup]="columnSettings" (ngSubmit)="saveColumnSettings()" class="p-inputgroup">
          <input pInputText placeholder="Enter Filter Name" type="text" formControlName="columnName">
          <button pButton pRipple label="Save" type="submit"></button>
        </form>
      </div>
    </div>
  </p-sidebar>

  <!-- Download Sidebar -->
  <p-sidebar [transitionOptions]="'0ms'" [(visible)]="showDownloadSidebar" class="downloadSidebar talentListDownload"
    [modal]="false" [style]="{ padding: '0', border: '2px solid white'}">
    <p class="sidebar-title">Download Data</p>

    <span class="p-input-icon-left">
      <em class="pi pi-search"></em>
      <input pInputText placeholder="Search" [(ngModel)]="downloadSearchTerm" type="text"
        (ngModelChange)="searchColumns($event)" />
    </span>

    <div class="field-checkbox" *ngFor="let col of colsToDownload">
      <p-checkbox [value]="col" [inputId]="col.field" [(ngModel)]="checkedColumns" name="category"></p-checkbox>
      <label [style]="{paddingLeft: '10px'}" [for]="col.field">{{col.header}}</label>
    </div>

    <div class="download-container">
      <p>Download as</p>
      <div class="radioButton-group">
        <div class="radioButton">
          <p-radioButton [(ngModel)]="downloadAs" value="excel" name="exportButtons" inputId="excel"></p-radioButton>
          <label for="excel"
            [style]="{color: downloadAs === 'excel' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
              class="pi pi-file"></em>Excel</label>
        </div>
        <div class="radioButton">
          <p-radioButton name="exportButtons" value="csv" inputId="csv" [(ngModel)]="downloadAs"></p-radioButton>
          <label for="csv" [style]="{color: downloadAs === 'csv' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
              class="pi pi-file-excel"></em>CSV</label>
        </div>
      </div>

      <button pButton pRipple type="button" label="Download" class="p-button-raised"
        (click)="downloadAs === 'excel' ? exportRole('Excel') : exportRole('csv')"></button>
    </div>

  </p-sidebar>

  <!-- Table -->
  <p-table (onHeaderCheckboxToggle)="allSelected()" #dt [columns]="_selectedColumns" [value]="talentList"
    [resizableColumns]="true" [rows]="pageSize" [loading]="loadingSpinner" [scrollable]="true" scrollHeight="76vh"
    scrollDirection="both" responsiveLayout="scroll" dataKey="id" [(selection)]="selectedUsersList"
    (selectionChange)="onSelectedTalentChange($event)" [exportFilename]="exportName">

    <ng-template pTemplate="caption">
      <div class="table-header">
        <span class="table-header-left">
          <button pButton class="p-button-text simple-btn{{showSettingsSidebar ? '-active' : '' }}"
            icon="pi pi-sliders-h"
            (click)="showSettingsSidebar = true; showFilterSidebar = false; showDownloadSidebar = false">
          </button>
          <button pButton class="p-button-text simple-btn{{showFilterSidebar ? '-active' : '' }}" icon="pi pi-filter" *ngIf="statusSelected == 'Active'" 
            (click)="showFilterSidebar = true; showSettingsSidebar = false; showDownloadSidebar = false">
          </button>
          <button pButton class="p-button-text simple-btn{{showDownloadSidebar ? '-active' : '' }}"
            icon="pi pi-download"
            (click)="showDownloadSidebar = true; showSettingsSidebar = false; showFilterSidebar = false">
          </button>
          <p-divider layout="vertical"></p-divider>
          <label [style]="{display: 'flex'}">Total List :
            <span [style]="{color: '#333333', marginLeft: '5px'}" *ngIf="!totalSkeleton">{{ totalLength }}</span>
            <p-skeleton width="3rem" height="1.2rem" styleClass="mb-2" *ngIf="totalSkeleton"></p-skeleton>
          </label>
        </span>
        <span class="table-menu">
          <p [style]="{paddingRight: '10px'}">View</p>
          <p-dropdown [(ngModel)]="pageSize" [style]="{ maxWidth: '5rem', fontSize: '0.9rem' }"
            (onChange)="statusSelected == 'Active' ? getTalentByLimit() : getInvitesByLimit()" [options]="pageLimits"></p-dropdown>
          <p-divider layout="vertical"></p-divider>
          <button class="disabled p-button-secondary p-button-text simple-btn" pButton>
            {{ first }} to {{ last }}
          </button>
          <button class="p-button-secondary p-button-text simple-btn" [disabled]="isFirstPage()" pButton
            icon="pi pi-angle-left" (click)="prev()"></button>
          <button (click)="next()" pButton class="p-button-secondary p-button-text simple-btn" icon="pi pi-angle-right"
            [disabled]="isLastPage()"></button>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr class="table-heading"
        [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}">
        <th scope="col" [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th [style]="{width: '13vw', padding: 0, margin: 0}" pFrozenColumn *ngIf="statusSelected == 'Active'" >
          Candidate ID
        </th>
        <th [style]="{width: '13vw', padding: 0, margin: 0}" pFrozenColumn *ngIf="statusSelected == 'Active'" >
          Candidate Name
        </th>
        <th scope="col" *ngFor="let talent of columns" pSortableColumn="{{ talent.field }}" [style]="{width: '13vw'}"
          pResizableColumn>
          {{ talent.header }}
          <p-sortIcon field="{{ talent.field }}" class="sort-icon"></p-sortIcon>
        </th>
      </tr>
      <tr class="selection-operations-row" *ngIf="privilege.editManageCandidate && selectedUsersList.length"
        [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}">
        <td pFrozenColumn class="selection-operations-options" *ngIf="statusSelected == 'Active'">
          <span>{{selectedUsersList.length}} Selected</span>
          <button pButton pRipple label="Move to" icon="pi pi-external-link" (click)="stagesOP.toggle($event)">
          </button>
          <button pButton pRipple label="Send Emails" icon="pi pi-send" (click)="sendEmail()"></button>
        </td>
        <p-overlayPanel #stagesOP class="moveToOverlay">
          <ng-template pTemplate>
            <div class="stageList" *ngIf="moveToSelected">
              <span class="p-input-icon-left" style="top: 0 !important; padding-left: 5px; padding-right: 5px;">
                <em class="pi pi-search"></em>
                <input (ngModelChange)="eventSearch($event)" type="text" pInputText placeholder="Search"
                  [(ngModel)]="eventSearchTerm">
              </span>
              <div class="options-container">
                <div *ngFor="let event of _eventDetails" class="stageList-options">
                  <p (click)="moveEvent(event, stagesOP)">{{event.name}}</p>
                </div>
              </div>
            </div>
          </ng-template>
        </p-overlayPanel>
      </tr>

    </ng-template>
    <ng-template pTemplate="body" let-candidate let-columns="columns">
      <tr class="row-candidate-list"
        [ngClass]="{'moveTable': showFilterSidebar || showSettingsSidebar || showDownloadSidebar}"
        [style]="{cursor: 'pointer'}">
        <td [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
          <p-tableCheckbox [value]="candidate"></p-tableCheckbox>
        </td>
        <td [style]="{width: '13vw', padding: 0, margin: 0}" pFrozenColumn *ngIf="statusSelected == 'Active'" (click)="openCandidates(candidate)">
          <span class="box-data">{{candidate['id']}}</span>
        </td>
        <td [ngClass]="{'capitalize': true}" [style]="{width: '13vw', padding: 0, margin: 0}" pFrozenColumn *ngIf="statusSelected == 'Active'" (click)="openCandidates(candidate)">
          <span class="box-data">{{candidate['name']}}</span>
        </td>
        
        <td (click)="statusSelected == 'Active' && openCandidates(candidate)" *ngFor="let col of columns" [style]="{width: '13vw'}">

          <span [ngSwitch]="true" class="box-data">

            <span *ngSwitchCase="col.field === 'status'" >
              <div *ngIf="candidate[col.field]" class="status-tag {{ candidate[col.field] == 'active' ? candidate[col.field] : 'invited' }}-tag">
                {{ candidate[col.field]?.charAt(0).toUpperCase() + candidate[col.field]?.slice(1) }}
              </div>
            </span>

            <span *ngSwitchCase="col.field === 'eventCategory'">
              {{candidate[col.field] == 'technical' ? 'Technical' : 'Non-Technical' }}
            </span>

            <span *ngSwitchCase="col.field === 'createdAt'">
              {{candidate[col.field] | date: dateFormat || '-'}}
            </span>

            <span *ngSwitchCase="col.field === 'eventDetails'" class="eventName-op">
              <span *ngIf="candidate[col.field] != null; else elseContent" style=" display: inline-flex; align-items: center; justify-content: flex-start;">
                <span [style]="{display: 'flex', flex: '1', alignItems: 'center'}">
                  <span [pTooltip]="candidate[col.field][0]?.eventTitle && candidate[col.field][0]?.eventTitle" tooltipPosition="bottom" class="splitcell">{{ candidate[col.field][0]?.eventTitle ? candidate[col.field][0]?.eventTitle : '-' }}</span>
                  <!-- <span class="cell-divider"> | </span> -->
                  <!-- <span class="status-tag {{ candidate[col.field][0]?.jobStatus }}-tag">
                      {{ candidate[col.field][0]?.jobStatus ? candidateStatus[candidate[col.field][0]?.jobStatus] : '-' }}
                  </span> -->
                  <div *ngIf="candidate[col.field].length > 1" class="remainingItems-badge">
                    <p-badge value="+{{candidate[col.field].length - 1}}" styleClass="mr-2"
                      (mouseenter)="events_op.show($event)" (mouseleave)="events_op.hide()"></p-badge>
                  </div>
                </span>
              </span>
              <ng-template #elseContent>-</ng-template>
              <p-overlayPanel #events_op [dismissable]="true">
                <ng-template pTemplate>
                  <div class="remainingItems" (mouseenter)="events_op.show($event)" (mouseleave)="events_op.hide()">
                    <!-- <p *ngFor="let event of candidate[col.field].slice(1)">{{event.eventTitle ?
                      event.eventTitle : event }}</p> -->
                    <p *ngFor="let event of candidate[col.field].slice(1)">
                      <span class="splitcell">{{ event?.eventTitle ? event?.eventTitle : '-' }}</span>
                      <!-- <span class="cell-divider"> | </span> -->
                      <!-- <span class="status-tag {{ event?.jobStatus }}-tag">
                          {{ event?.jobStatus ? candidateStatus[event?.jobStatus] : '-' }}
                      </span> -->
                    </p>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </span>
            <span *ngSwitchCase="col.field === 'eventStage'" class="eventName-op">
              <span *ngIf="candidate['eventDetails'] != null; else elseContent" style=" display: inline-flex; align-items: center; justify-content: flex-start;">
                <span [style]="{display: 'flex', flex: '1', alignItems: 'center'}">
                  <span [pTooltip]="candidate['eventDetails'][0]?.stageName && candidate['eventDetails'][0]?.stageName" tooltipPosition="bottom" class="splitcell">{{ candidate['eventDetails'][0]?.stageName ? candidate['eventDetails'][0]?.stageName : '-' }}</span>
                </span>
              </span>
              <ng-template #elseContent>-</ng-template>
            </span>
            <span *ngSwitchCase="col.field === 'eventStatus'" class="eventName-op">
              <span *ngIf="candidate['eventDetails'] != null; else elseContent" style=" display: inline-flex; align-items: center; justify-content: flex-start;">
                <span [style]="{display: 'flex', flex: '1', alignItems: 'center'}">
                  <span class="status-tag {{ candidate['eventDetails'][0]?.jobStatus }}-tag">
                    {{ candidate['eventDetails'][0]?.jobStatus ? 
                    candidate['eventDetails'][0]?.jobStatus?.charAt(0).toUpperCase() + candidate['eventDetails'][0]?.jobStatus?.slice(1) : '-' }}
                  </span>
                </span>
              </span>
              <ng-template #elseContent>-</ng-template>
            </span>

            <span *ngSwitchCase="col.field === 'invitedEvents'" class="eventName-op box-data">
              <span *ngIf="candidate[col.field] != null; else elseContent" style=" display: inline-flex; align-items: center; justify-content: flex-start;">
                {{ getEventName(candidate[col.field][0]) }}

                <div *ngIf="candidate[col.field].length > 1" class="remainingItems-badge">
                  <p-badge value="+{{candidate[col.field].length - 1}}" styleClass="mr-2"
                    (mouseenter)="events_op.show($event)"></p-badge>
                </div>
              </span>
              <ng-template #elseContent>-</ng-template>
              <p-overlayPanel #events_op [dismissable]="true">
                <ng-template pTemplate>
                  <div class="remainingItems" (mouseenter)="events_op.show($event)" (mouseleave)="events_op.hide()">
                    <p *ngFor="let event of candidate[col.field].slice(1)">{{ getEventName(event) }}</p>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </span>

            <span *ngSwitchDefault>
              {{ candidate[col.field] ? candidate[col.field] : '-' }}
            </span>
          </span>

        </td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="displayCandidateDetails">
    <p-sidebar class="candidateDetaile" [transitionOptions]="'0ms'" [(visible)]="displayCandidateDetails"
        styleClass="p-sidebar-lg" position="right" [style]="{width: '921px'}">
        <app-candidate-info [selectedUser]="selectedUser" [disableFeedback]="true" [disableActivity]="true" [disableStage]="true" [numberOfStages]="0"></app-candidate-info>
    </p-sidebar>
</div>
</div>


<p-menu #menu [popup]="true" [model]="talentOptions"></p-menu>
<p-toast></p-toast>