<div *ngIf="!enableScreen">
  <div class="get-verified" *ngIf="!verificationRequired || (mailVerified || mobileVerified)">
    <div class="mediaAccess-overlay" *ngIf="!mediaAccess">
      <div class="mediaAccess">
          <img src="../../assets/icons-svg/Warning.svg" alt="Warning">
          <p class="warning">NOTE !</p>
          <p class="message">Kindly enable microphone and camera access in your browser.</p>
          <!-- <br>
          <strong class="message message-italic">Kindly check your email for further notifications.</strong> -->
      </div>
    </div>
    
    <div *ngIf="isLoading" class="loadingOverlay">
      <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
      </p-progressSpinner>
    </div>
    <div *ngIf="!isLoading">
      <div *ngIf="!interviewStarted" class="candidate-container">
        <div class="candidate-header">
        </div>
        <div class="candidate-display">
          <div class="smile-emoji">
            <img src="../../assets/icons-svg/Smile.svg" alt="Emoji">
          </div>
          <div class="candidate-full-content">
            <div class="candidate-body">
              Hi {{userEventData.name}},
            </div>
            <div *ngIf="!unscheduleTimeErr && !meetingEnded && !reloadInterview" class="candidate-body-content">
              Please wait until interviewer allows you.
            </div>
            <div *ngIf="unscheduleTimeErr && !meetingEnded && !reloadInterview" class="candidate-body-content">
              Please join in the schedule time {{scheduleDT}}
            </div>
            <div *ngIf="meetingEnded && !reloadInterview" class="candidate-body-content">
              Your Interview has been Completed.
            </div>
            <div *ngIf="reloadInterview" class="candidate-body-content">
              Reloading Interview.
            </div>
          </div><br>
          <div class="instruction-content" *ngIf="!meetingEnded">
            <p style="text-align: center; text-decoration: underline;">MANDATORY INSTRUCTIONS:</p><br>
            <ol>
              <li>According to your browser settings, enable microphone and camera access before your interview starts.</li>
              <li>Kindly allow screenshare while joining interview.</li>
              <li>Avoid reloading/closing your interview screen during the interview. It will terminate the interview.</li>
            </ol>
          </div>
        </div>
      </div>
    
      <button *ngIf="interviewStarted" pButton class="joinBtn" label="Open IDE" [loading]="loadIDE" (click)="openIDE()"></button>
      <div *ngIf="interviewStarted" id="jaas-container" class="jitsi-container"
        [ngStyle]="{'display': interviewStarted ? 'block' : 'none' }">
      </div>
    </div>
  </div>
  <!-- [emailToVerify]="candidateEmail"  -->
  
  <div class="verification-call" *ngIf="verificationRequired && (!mailVerified && !mobileVerified)">
      <app-otp-verification 
      [candidateId]="userId"
      [mobileToVerify]="candidateMobile" 
      [eventId]="eventId"
      (mailVerified)=" initiateFuntion($event);"
      (mobileVerified)="initiateFuntion($event);"
      ></app-otp-verification>
  </div>
</div>
<div *ngIf="enableScreen" class="loadingOverlay">
  <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
  </p-progressSpinner>
</div>
