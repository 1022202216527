import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { OtpService } from '../services/otp.service';
import { nanoid } from 'nanoid';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { GlobalServicesService } from '../services/global-services.service';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ForgetPasswordComponent implements OnInit {

  @ViewChild('otpEmail') otpEmail: any;
  @Input() emailToVerify: string = '';
  @Output() cancelForgetPassword = new EventEmitter<boolean>();
  
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,24}$/;
  otpValue: any;
  infoMessage: any = {};
  intervalId: any;
  btnLoading: boolean = false;
  pageLoading: boolean = false;
  redirecting: boolean = false;
  sendOtpState: boolean = true;
  verifyOtpState: boolean = false;
  waitToResend: boolean = false;
  loadResend: boolean = false;
  clicked: boolean = false;
  invalidOTP: boolean = false;
  expiredOTP: boolean = false;
  displayInfoMessage: boolean = false;
  resetPasswordState : boolean = false;
  showNewPassword : boolean = false;
  showConfirmPassword : boolean = false;
  passwordPattern: any = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;

  otpTimer: number = 0;
  countdown: string = '';

  passwordForm: FormGroup;

  constructor(private otpService: OtpService,private fb: FormBuilder, private loginService: LoginService, private globalService: GlobalServicesService, private router: Router, private messageService: MessageService,private confirmationService: ConfirmationService) { 
    this.passwordForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/)]],
      confirmPassword: ['', [Validators.required]],
    }, { validator: this.passwordMatchValidator });
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const newPassword = formGroup.get('newPassword')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;

    if (newPassword === confirmPassword) {
      console.log("return null ")
      return null;
    } else {
      console.log("Mismatch Error")
      formGroup.get('confirmPassword')?.setErrors({ mismatch: true });
      return { mismatch: true };
    }
  }

  ngOnInit(): void {
  }

  sendOtpByEmail = () => {
      this.btnLoading = true;
      this.otpService.sendOtpFP(this.emailToVerify.toLowerCase()).subscribe((res:any) => {
        if (res.data == "OTP Sent Successfully") {
          this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.emailToVerify, bgColor: '#55BA45' }
          this.showInfoMessage();
          this.btnLoading = false;
          this.sendOtpState = false;
          this.verifyOtpState = true;
          this.waitToResend = true;
          this.startOtpTimer();
        } else if(res.data?.data == 'Email or password is invalid') {
          this.infoMessage = { icon: 'pi pi-times', message: 'Invalid email or Email does not exist!', bgColor: '#E24A47' }
          this.btnLoading = false;
          this.showInfoMessage();
        } else {
          this.infoMessage = { icon: 'pi pi-times', message: 'Something went wrong!', bgColor: '#E24A47' }
          this.btnLoading = false;
          this.showInfoMessage();
        }
      })
  }

  verifyOtp = () => {
    this.clicked = true;
    if (this.otpValue.toString().length == 6) {
      this.btnLoading = true;
      this.invalidOTP = false;
      this.expiredOTP = false;
      let payload = { otp: Number(this.otpValue) };
      this.otpService.verifyOtp(this.emailToVerify.toLowerCase(), payload).subscribe((res:any) => {
        this.btnLoading = false;
        switch (res.data) {
          case 'success': {
              this.invalidOTP = false;
              this.clicked = false;
              this.resetPasswordState = true;
          } break;
          case 'failed': {
            this.invalidOTP = true;
            this.infoMessage = { icon: 'pi pi-check', message: 'Invalid OTP, Please ', tryAgain: ' Try Again!', bgColor: '#ba4545' }
            // this.showInfoMessage();
          } break;
          case 'expired': {
            this.expiredOTP = true;
            this.infoMessage = { icon: 'pi pi-check', message: 'OTP Expired!', bgColor: '#ba4545' }
            // this.showInfoMessage();
          }
        }
      })
    }
  }

  resendOtp = () => {
    this.loadResend = true;
    let payload = {
      verificationType : '2FA',
    }
    this.otpService.sendOtp(this.emailToVerify.toLowerCase(), nanoid(10),payload).subscribe((res:any) => {
      this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.emailToVerify, bgColor: '#55BA45' }
      this.showInfoMessage();
      this.loadResend = false;
      this.waitToResend = true;
      this.startOtpTimer();
    })
  }

  startOtpTimer() {
    this.otpTimer = 60;
    this.countdown = this.formatTime(this.otpTimer);
    this.waitToResend = true;

    this.intervalId = setInterval(() => {
      this.otpTimer--;
      this.countdown = this.formatTime(this.otpTimer);

      if (this.otpTimer === 0) {
        clearInterval(this.intervalId);
        this.waitToResend = false;
      }
    }, 1000);
  }

  formatTime(seconds: number): string {
    return `${seconds.toString().padStart(2, '0')}`;
  }

  restrictNumber(event: any) {
    let e: any = event || window.event;
    let key = e.keyCode || e.which;

    if (key == 13) {
      this.verifyOtp();
    }
    if (key < 48 || key > 57 || event.target.value.toString().length >= 6) {
      if (e.preventDefault) e.preventDefault();
      e.returnValue = false;
    }
  }

  showInfoMessage = () => {
    this.displayInfoMessage = true;
    setInterval(() => {
      this.displayInfoMessage = false;
      this.infoMessage = {};
    }, 10000);
  }

  otpValueChange(event: any) {
    if (event.length == 0 || event.length == 6) {
      this.invalidOTP = false;
      this.expiredOTP = false;
    }
  }

  showToggle(passwordInputClass: string, toggleButton: string)
  {
    let passwordInput: any = document.getElementsByClassName(passwordInputClass)[0];
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
      toggleButton == 'newPassword' ? this.showNewPassword = true : this.showConfirmPassword = true;
    } else {
      passwordInput.type = "password";
      toggleButton == 'newPassword' ? this.showNewPassword = false : this.showConfirmPassword = false;
    }
  }

  confirm() {
    console.log(this.confirm)
    this.confirmationService.confirm({
        message: 'Are you sure you want to reset your password?',
        accept: () => {
            this.resetPassword()
        }
    });
  }

  resetPassword(){
    this.btnLoading = true;
    let password = this.passwordForm.get('confirmPassword')?.value;
    this.loginService.updatePassword(this.emailToVerify.toLowerCase(), {password: password}).subscribe(res => {
      this.btnLoading = false;
      if(res.data?.id){
        this.redirectLogin();
        
      } else {
        this.btnLoading = false;
        this.infoMessage = { icon: 'pi pi-check', message: 'Something went wrong!' + this.emailToVerify, bgColor: '#ba4545' }
        this.showInfoMessage();
      }
    })

  }

  redirectLogin(){
    this.redirecting = true;
    setTimeout(() => {
      this.redirecting = false;
      this.router.navigateByUrl('/login')
    }, 5000)
  }

  loginUser(email: string, password: string){
    this.pageLoading = true;
    let payload: any = {
      email: email,
      password: password
    }
    this.loginService.login(payload).subscribe((response) => {
      let data = response.data;
      
      let userDetails: any = {
        email: data.email,
        id: data.userId,
        name: data.userDetails.name,
      }
      
      let roleType: any = data.userDetails.userType;
      this.globalService.getUserPrivileges(roleType).subscribe((response) => {
        if (response.status === 200) {
            this.pageLoading = false;
            let routingDetails = data;
            let privilegeData = response.data;

            localStorage.setItem("userPrivilege", privilegeData);
            localStorage.setItem('userDetails', JSON.stringify(userDetails));
            localStorage.setItem('token', routingDetails.userToken);
            this.router.navigateByUrl('/events');

        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something Went Wrong!' });
          this.pageLoading = false;
        }
      })
  })
}
}
