import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { LoginService } from '../services/login.service';
import { GlobalServicesService } from '../services/global-services.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as CryptoJS from 'crypto-js';
import { FileUploadService } from '../services/file-upload.service';
import { OtpService } from '../services/otp.service';
import { nanoid } from 'nanoid';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit, OnChanges {
  @Input() companyLogo: string = '';

  passwordShow: boolean = false;
  height: string = window.innerHeight.toString() + 'px';
  isLoading: boolean = false;
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,24}$/;
  loginSection = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    password: new FormControl('', [Validators.required, Validators.minLength(1)])
  });
  invalidCred: boolean = false;
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;
  sourceImg: string = `${this.s3Domain}/${this.bucketName}/general-settings/company-details/company-logo/default-logo.png`;
  coverImage: string = '';
  loginEmail: string = '';
  clicked: boolean = false;
  otpRequired: boolean = false;
  otpVerified: boolean = false;
  forgetPassword: boolean = false;
  schoolData: any;
  schoolCode: any;
  userDetails: any;
  routingDetails: any;
  privilegeData: any;
  constructor(
      private loginService: LoginService, 
      private router: Router, 
      private globalService: GlobalServicesService, 
      private messageService: MessageService, 
      private fileUploadService: FileUploadService,
      private otpService: OtpService
    ) {
  }

  ngOnInit(): void {
    this.schoolData = localStorage.getItem('schoolData')
    ? JSON.parse(localStorage.getItem('schoolData') || '')
    : '';

    this.schoolCode = this.schoolData.school_code;
    let schoolId = this.schoolData.school_id;
    if (this.schoolCode === 'wileyedge399' || this.schoolCode === 'wileyedge537' || this.schoolCode === 'kscollege408') {
      this.coverImage = `${this.s3Domain}/${this.bucketName}/general-settings/company-details/company-logo/${schoolId}/cover.png`;
    }
    else {
      this.coverImage = `${this.s3Domain}/${this.bucketName}/general-settings/company-details/company-logo/default-cover.jpg`;
    }
  }

  ngOnChanges(): void {
    if (this.companyLogo.length) {
      this.sourceImg = this.companyLogo;
    }
  }

  onResize() {
    this.height = "100vh";
  }

  toggleShowHidePwd() {
    let x: any = document.getElementsByClassName("passwordInput")[0];
    if (x.type === "password") {
      x.type = "text";
      this.passwordShow = true;
    } else {
      x.type = "password";
      this.passwordShow = false;
    }
  }

  signIn() {
    this.clicked = true;
    this.loginSection.get('email')?.markAsTouched();
    this.loginSection.get('password')?.markAsTouched();
    if (this.loginSection.valid) {
      this.isLoading = true;
      let payload: any = {
        email: this.loginSection.value.email.toLowerCase(),
        password: this.loginSection.value.password
      }
      this.loginService.login(payload).subscribe((response) => {
        this.isLoading = false;
        let data = response.data;
        if (data && data !== 'Email or password is invalid') {
          let userDetails: any = {
            email: data.email,
            id: data.userId,
            name: data.userDetails.name,
          }
          this.userDetails = userDetails;
          // localStorage.setItem('userDetails', JSON.stringify(userDetails));
          // localStorage.setItem('token', data.userToken);
          let roleType: any = data.userDetails.userType;
          this.globalService.getUserPrivileges(roleType).subscribe((response) => {
            if (response.status === 200) {
              this.clicked = false;
              this.loginEmail = this.loginSection.value.email;
              let payload = {
                verificationType : '2FA',
              }
              this.otpService.sendOtp(this.loginEmail.toLowerCase(), nanoid(10),payload).subscribe((res:any) => {
                this.routingDetails = data;
                this.otpRequired = true;
                this.privilegeData = response.data;
              })
            }
            else {
              this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something Went Wrong!' });
            }
          })
        }
        else {
          this.invalidCred = true;
        }
      })
    }
  }
}
