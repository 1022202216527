import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { OtpService } from 'src/app/services/otp.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.css']
})
export class OtpVerificationComponent implements OnInit, OnChanges {

  @ViewChild('otp_value') otp_value: any;

  @Input() candidateId: string = '';
  @Input() eventId: string = '';
  @Input() emailToVerify: string = '';
  @Input() mobileToVerify: string = '';
  @Output() otpVerified = new EventEmitter<boolean>();
  @Output() emailVerified = new EventEmitter<boolean>();
  @Output() mailVerified = new EventEmitter<boolean>();
  @Output() mobileVerified = new EventEmitter<boolean>();

  otpValue: any;
  userName: string = '';
  infoMessage: any = {};
  intervalId: any;

  btnLoading: boolean = false;
  pageLoading: boolean = false;
  sendOtpState: boolean = true;
  verifyOtpState: boolean = false;
  verifyMail: boolean = false;
  verifyMobile: boolean = false;
  waitToResend: boolean = false;
  loadResend: boolean = false;
  clicked: boolean = false;
  invalidOTP: boolean = false;
  expiredOTP: boolean = false;
  displayInfoMessage: boolean = false;
  verifyByEmail: boolean = false;
  verifyByMobile: boolean = false;
  enableMobile: boolean = false;

  otpTimer: number = 0;
  countdown: string = '';
  redirectUrl: string = '';

  candidateName: string = '';
  testName: string = '';
  testDuration: string = ''; 
  testId: string = '';
  stageId: string = '';
  verifyCategory: string = '';

  _selectedCategory: string = 'email';

  constructor(private otpService: OtpService, private activatedRoute: ActivatedRoute, private globalService: GlobalServicesService,) { }

  ngOnInit(): void {
    if (window.location.pathname === '/verification') {
      this.activatedRoute.queryParamMap.subscribe((param) => {
        this.candidateId = param.get('candidateId') || '';
        this.candidateName = param.get('candidateName') || '';
        this.testName = param.get('testName') || '';
        this.testDuration = param.get('testDuration') || '';
        this.testId = param.get('test_id') || '';
        this.stageId = param.get('stageId') || '';
        this.verifyCategory = param.get('verifyCategory') || '';
        if (this.eventId === '') {
          this.eventId = param.get('eventId') || '';
        }
      })
      this.redirectUrl = window.location.href.split('&redirect=')[1];
      this.getUserData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['candidateId'] && this.candidateId !== '') {
      this.getUserData();
    }
    if (changes['emailToVerify'] && this.emailToVerify !== '') {
      this.verifyByEmail = true;
      this.userName = "Candidate";
    }
  }

  getUserData = () => {
    this.pageLoading = true;
    this.globalService.getSchoolDetails().subscribe((res: any) => {
      if (res.status == 200) {
        this.enableMobile = res.data[0].otpSettings?.beforeInterview;
        this.otpService.getUserById(this.candidateId).subscribe((res: any) => {
          this.pageLoading = false;
          if (res.data) {
            this.emailToVerify = res.data.email;
            this.userName = res.data.name;
          }
        })
      }
    })
  }

  sendOtp = () => {
    if (this._selectedCategory == 'email') {
      this.btnLoading = true;
      this.otpService.sendOtp(this.candidateId, this.eventId).subscribe(res => {
        if (res.data == "OTP Sent Successfully") {
          this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.emailToVerify, bgColor: '#55BA45' }
          this.showInfoMessage();
          this.btnLoading = false;
          this.sendOtpState = false;
          this.verifyOtpState = true;
          this.verifyMail = false;
          this.verifyMobile = true;
          this.waitToResend = true;
          this.startOtpTimer();
        } else {
          this.infoMessage = { icon: 'pi pi-times', message: 'Something went wrong!', bgColor: '#E24A47' }
          this.showInfoMessage();
        }
      })
    } else {
      this.btnLoading = true;
      let payload = {
        "mobileNumber": this.mobileToVerify,
      }
      this.otpService.sendOtpByMobile(payload, 'interview').subscribe(res => {
        if (res.data) {
          this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.mobileToVerify, bgColor: '#55BA45' }
          this.showInfoMessage();
          this.btnLoading = false;
          this.sendOtpState = false;
          this.verifyOtpState = true;
          this.verifyMail = true;
          this.verifyMobile = false;
          this.waitToResend = true;
          this.startOtpTimer();
        } else {
          this.infoMessage = { icon: 'pi pi-times', message: 'Something went wrong!', bgColor: '#E24A47' }
          this.showInfoMessage();
        }
      })
    }
  }

  sendOtpByEmail = () => {
    if (this._selectedCategory == 'email') {
      this.btnLoading = true;
      this.otpService.sendOtpByEmail(this.emailToVerify, this.eventId).subscribe(res => {
        if (res.data == "OTP Sent Successfully") {
          this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.emailToVerify, bgColor: '#55BA45' }
          this.showInfoMessage();
          this.btnLoading = false;
          this.sendOtpState = false;
          this.verifyOtpState = true;
          this.verifyMail = false;
          this.verifyMobile = true;
          this.waitToResend = true;
          this.startOtpTimer();
        } else {
          this.infoMessage = { icon: 'pi pi-times', message: 'Something went wrong!', bgColor: '#E24A47' }
          this.showInfoMessage();
        }
      })
    } else {
      this.btnLoading = true;
      let payload = {
        "mobileNumber": this.mobileToVerify,
      }
      this.otpService.sendOtpByMobile(payload, 'interview').subscribe(res => {
        if (res.data) {
          this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.mobileToVerify, bgColor: '#55BA45' }
          this.showInfoMessage();
          this.btnLoading = false;
          this.sendOtpState = false;
          this.verifyOtpState = true;
          this.verifyMail = true;
          this.verifyMobile = false;
          this.waitToResend = true;
          this.startOtpTimer();
        } else {
          this.infoMessage = { icon: 'pi pi-times', message: 'Something went wrong!', bgColor: '#E24A47' }
          this.showInfoMessage();
        }
      })
    }
  }

  verifyOtp = () => {
    if (this._selectedCategory == 'email') {
      this.clicked = true;
      if (this.otpValue.toString().length == 6) {
        this.btnLoading = true;
        this.invalidOTP = false;
        this.expiredOTP = false;
        let payload = { otp: Number(this.otpValue) };
        this.otpService.verifyOtp(this.emailToVerify, payload).subscribe(res => {
          this.btnLoading = false;
          switch (res.data) {
            case 'success': {
              this.btnLoading = false;
              this.infoMessage = { icon: 'pi pi-check', message: 'Verification Successfull!', bgColor: '#55BA45' }
              if (window.location.pathname === '/verification') {
                this.invalidOTP = false;
                this.mailVerified.emit(true);
                if(this.verifyCategory === 'test')
                {
                  this.btnLoading = true;
                  let ssoPayload = {
                    "email": this.emailToVerify,
                    "eventJobId": this.eventId,
                    "stageId": this.stageId,
                    "test_id": this.testId,
                    "userId": this.candidateId,
                    "name": this.userName
                  }
                  this.otpService.ssoTestlink(ssoPayload).subscribe((res)=>{
                    this.btnLoading = false;
                    if(res.status === 200)
                    {
                      window.open(decodeURIComponent(res.data), '_self');
                    }
                    else{
                      this.infoMessage = {icon: 'pi pi-times', message: 'Something went wrong!', bgColor: '#E24A47'}
                      this.showInfoMessage();
                    }
                  })
                }
                else
                {
                  window.open(decodeURIComponent(this.redirectUrl), '_self');
                }
              } else {
                this.mailVerified.emit(true);
                this.invalidOTP = false;
                this.otpVerified.emit(true);
                if (this.verifyByEmail) {
                  this.emailVerified.emit(true);
                }
                this.clicked = false;
              }
            } break;
            case 'failed': {
              this.invalidOTP = true;
            } break;
            case 'expired': {
              this.expiredOTP = true;
            }
          }
        })

      } else {
        this.mailVerified.emit(false);
        this.invalidOTP = true;
      }
    } else {
      this.clicked = true;
      if (this.otpValue.toString().length == 6) {
        this.btnLoading = true;
        this.invalidOTP = false;
        this.expiredOTP = false;
        let payload = { mobileNumber: this.mobileToVerify, otp: Number(this.otpValue) };
        this.otpService.verifyOtpByMobile(payload, 'interview').subscribe(res => {

          switch (res.data) {
            case 'success': {
              this.btnLoading = false;
              if (window.location.pathname === '/verification') {
                this.infoMessage = { icon: 'pi pi-check', message: 'Verification Successfull!', bgColor: '#55BA45' }
                this.mobileVerified.emit(true);
                this.invalidOTP = false;
                this.clicked = false;
              } else {
                this.infoMessage = { icon: 'pi pi-check', message: 'Verification Successfull!', bgColor: '#55BA45' }
                this.mobileVerified.emit(true);
                this.invalidOTP = false;
                this.clicked = false;
              }
            } break;
            case 'failed': {
              this.invalidOTP = true;
            } break;
            case 'expired': {
              this.expiredOTP = true;
            }
          }
          this.btnLoading = false;
        })

      } else {
        this.mobileVerified.emit(true);
        this.invalidOTP = true;
      }
    }
  }

  resendOtp = () => {
    this.loadResend = true;
    if (this._selectedCategory == 'mobile') {
      let payload = {
        "mobileNumber": this.mobileToVerify,
      }
      this.otpService.sendOtpByMobile(payload, 'interview').subscribe(res => {
        this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.emailToVerify, bgColor: '#55BA45' }
        this.showInfoMessage();
        this.loadResend = false;
        this.waitToResend = true;
        this.startOtpTimer();
      })
    } else {
      if (this.verifyByEmail) {
        this.otpService.sendOtpByEmail(this.emailToVerify, this.eventId).subscribe(res => {
          this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.emailToVerify, bgColor: '#55BA45' }
          this.showInfoMessage();
          this.loadResend = false;
          this.waitToResend = true;
          this.startOtpTimer();
        });
      } else {
        this.otpService.sendOtp(this.candidateId, this.eventId).subscribe(res => {
          this.infoMessage = { icon: 'pi pi-check', message: 'OTP has been sent to ' + this.emailToVerify, bgColor: '#55BA45' }
          this.showInfoMessage();
          this.loadResend = false;
          this.waitToResend = true;
          this.startOtpTimer();
        })
      }
    }
  }

  startOtpTimer() {
    this.otpTimer = 60;
    this.countdown = this.formatTime(this.otpTimer);
    this.waitToResend = true;

    this.intervalId = setInterval(() => {
      this.otpTimer--;
      this.countdown = this.formatTime(this.otpTimer);

      if (this.otpTimer === 0) {
        clearInterval(this.intervalId);
        this.waitToResend = false;
      }
    }, 1000);
  }

  formatTime(seconds: number): string {
    return `${seconds.toString().padStart(2, '0')}`;
  }

  restrictNumber(event: any) {
    let e: any = event || window.event;
    let key = e.keyCode || e.which;

    if (key == 13) {
      this.verifyOtp();
    }
    if (key < 48 || key > 57 || event.target.value.toString().length >= 6) {
      if (e.preventDefault) e.preventDefault();
      e.returnValue = false;
    }
  }

  showInfoMessage = () => {
    this.displayInfoMessage = true;
    setInterval(() => {
      this.displayInfoMessage = false;
      this.infoMessage = {};
    }, 10000);
  }

  otpValueChange(event: any) {
    if (event.length == 0 || event.length == 6) {
      this.invalidOTP = false;
      this.expiredOTP = false;
    }
  }
}
