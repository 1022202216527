import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { JobServicesService } from 'src/app/services/job-services.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { GlobalServicesService } from 'src/app/services/global-services.service';

export enum UserTypes {
  superAdmin = 'Super Admin',
  admin = 'Admin',
  recruiter = 'Recruiter',
  evaluator = 'Evaluator',
  interviewer = 'Interviewer'
}

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css'],
  providers: [MessageService]
})

export class AddUserComponent implements OnChanges {

  @Input() data: any;
  associativeRoles: SelectItem[];
  saveBtnLoading: boolean = false;
  showInfo: boolean = false;
  clicked: boolean = false;
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,24}$/;
  password: any = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
  userPrivilages: any;
  userRole: string = '';

  encryptKey = environment.ENCRYPTION.ENCRYPTION_KEY;

  addUserForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    password: new FormControl('', [Validators.required, Validators.pattern(this.password)]),
    mobile_number: new FormControl(null, [ Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    userType: new FormControl(null)
  })

  showPassword: boolean = false;

  constructor(private jobServices: JobServicesService, private messageService: MessageService, private globalService: GlobalServicesService) {
    this.associativeRoles = Object.keys(UserTypes).map((key: string) => ({ label: UserTypes[key as keyof typeof UserTypes], value: key }));
    this.userPrivilages = JSON.parse(this.globalService.decryptPrivileges()).userPrivileges;
    this.userRole = JSON.parse(this.globalService.decryptPrivileges()).userType;
  }

  ngOnChanges(): void {
    if (this.data) {
      this.addUserForm.patchValue(this.data)
    }
    if(this.userRole != 'superAdmin'){
      this.associativeRoles[0].disabled = true;
      this.associativeRoles[1].disabled = true;
    } else {
      this.associativeRoles[0].disabled = true;
    }
  }

  saveUser = () => {
    this.clicked = true;
    if (this.addUserForm.valid) {
      this.saveBtnLoading = true;
      let payload = this.addUserForm.value;
      payload.email = payload.email.toLowerCase();
      payload.mobile_number = parseInt(payload.mobile_number);
      this.jobServices.createUser(payload).subscribe(res => {
        if (Object.keys(res.data).length === 0) this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to add user!' });
        else if(res.data == "User already exists"){
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'User already exists!' });
        }
        else {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'User Added Successfully' });
          this.addUserForm.reset();
        }
        this.saveBtnLoading = false;
        this.clicked = false;
      })
    }
  }

  onSave() { }

  cancel = () => {
    this.addUserForm.reset();
    this.showInfo = false;

  }


  infoData: any[] = [
    { name: 'superAdmin', description: 'Can access & modify all configurations in the settings and will have complete access to all modules.' },
    { name: 'admin', description: 'Can access & modify all configurations in the settings and will have complete access to all modules but are restricted from modify candidate details.' },
    { name: 'recruiter', description: 'Has access to view, move candidates between stages, and change candidate statuses. However, there are restrictions in place to prevent them from editing or deleting other details.' },
    // { name: 'hiringManager', description: 'Hiring Manager Privileges' },
    { name: 'evaluator', description: 'Has focused access rights, limited to specific events, the "stage" tab within those events, specifically the "Test stages," and access to scores and result analysis. This configuration ensures that evaluators can efficiently carry out their responsibilities related to assessing candidates in the specified test stages.' },
    { name: 'interviewer', description: 'Has focused access rights, limited to specific events, the "interview" tab within those events, and specifically the "interview stage". This setup ensures that interviewers can perform their assigned tasks efficiently without unnecessary access to unrelated information in the platform.' },
  ];
  selectedName!: string;
  dis !: any;

  onClick() {

    this.showInfo = true;
    this.selectedName = this.addUserForm.value.userType;
    for (let data of this.infoData) {
      if (data.name === this.selectedName) {
        this.dis = data.description;
        break;
      }
    }
  }

  restrictNumber(event: any) {
    let e: any = event || window.event; 
    let key = e.keyCode || e.which;

    if (key < 48 || key > 57) { 
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
    }
}

showToggle()
{
  let passwordInput: any = document.getElementsByClassName("passwordInput")[0];
  if (passwordInput.type === "password") {
    passwordInput.type = "text";
    this.showPassword = true;
  } else {
    passwordInput.type = "password";
    this.showPassword = false;
  }
}
}
