import { Component, OnInit, HostListener } from '@angular/core';
import { EventServicesService } from 'src/app/services/event-services.service';
import { SocketService } from "../../services/socket.service";
import { ActivatedRoute } from '@angular/router';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';

declare var JitsiMeetExternalAPI: any;
@Component({
    selector: 'jitsi-container',
    templateUrl: './jitsi-container.html',
    styleUrls: ['./jitsi-container.css'],
    providers: [MessageService],
})

export class JitsiContaineComponent implements OnInit {

    public interviewStarted: boolean = false;
    public jitsiAPI: any;
    public userId: any;
    public stageId: any;
    public eventId: any;
    public c_userName: any;
    public schoolId: any;
    public candidateEmail: any;
    public candidateName: any
    public showAddRole: boolean = false;
    public feedbackSlider: boolean = false;
    public paramItems: any;
    public roomName: string = '';
    public meetingEnd: boolean = false;
    public formName: string = '';
    meetingStatus: any;
    adminReload: boolean = false;
    degreeDetails: any;
    assessmentScore: any = [];
    isLoading: boolean = false
    ENV = environment.HOST.NODE_ENV;
    candidateDetails: any;
    candidateAssessmentDetails: any;
    userEventData: any;

    //Boolean
    showBackNext: boolean = true;


    constructor(private eventServices: EventServicesService, private socketService: SocketService, private activatedRoute: ActivatedRoute, private breadcrumbService: NgDynamicBreadcrumbService, private globalService: GlobalServicesService, private messageService: MessageService,) { }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: any) {
        this.adminReload = true;
        localStorage.setItem('meetingStatus', this.meetingStatus);
        return true;
    }

    ngOnInit(): void {
        setTimeout(() => {
            const a: any = document.getElementById("score-head")
            a.offsetWidth;
            const b: any = document.getElementById("score-container")
            b.offsetWidth;
            if (a < b) {
                this.showBackNext = true;
            }
        }, 1000);

        this.adminReload = false;
        this.activatedRoute.queryParamMap.subscribe((param: any) => {
            this.userId = param.get('userID')
            this.eventId = param.get('eventId')
            this.c_userName = param.get('c_userName')
            this.schoolId = param.get('schoolId')
            this.candidateName = param.get('candidateName')
            this.candidateEmail = param.get('candidateEmail')
            this.stageId = param.get('stageId')
            this.roomName = param.get('roomName')
            this.paramItems = param.params;
        })

        this.updateBreadcrumb();
        this.getFeedbackFormName();
        this.eventServices.getSpcCandidateEntry({
            eventId: this.eventId,
            userId: this.userId,
            schoolId: this.schoolId,
            stageId: parseInt(this.stageId)
        }).subscribe(async (response) => {

            if (response?.data?.data) {
                this.userEventData = response.data.data;
                let locStatus = localStorage.getItem('meetingStatus');
                if (locStatus) {
                    this.meetingStatus = locStatus;
                } else {
                    this.meetingStatus = this.userEventData.status;
                }
                if (this.userEventData.status == 'meetingEnd' && this.meetingStatus == 'meetingEnd') {
                    this.meetingEnd = true;
                } else {
                    this.initiateCall();
                }
            }
        })

        this.eventServices.getUserById(this.userId).subscribe((response: any) => {
            let data = response.data;
            this.degreeDetails = data.degreeDetails;
        })
        this.eventServices.getAllTestScoreByEmail(this.eventId, this.candidateEmail).subscribe((response) => {
            this.assessmentScore = response.data ?? [];
        })

        this.eventServices.getInterviewDetails(this.eventId, this.candidateEmail).subscribe((response: any) => {
            let res = response.data;
            if (response.status == 200) {
                this.candidateDetails = res;
                this.candidateAssessmentDetails = res.scoreDetails;
            }
        })
    }

    toggleFunction(op: any, event: any) {
        op.toggle(event);
    }

    public handleSectionTabNext() {
        let a: any = document.getElementById("score-container");
        a.scrollTo({
            behavior: "smooth",
        });
        a.style.transition = "all 0.5s";
        a.scrollLeft += 200;
    }

    public handleSectionTabBack() {
        let a: any = document.getElementById("score-container");
        a.scrollTo({
            behavior: "smooth",
        });
        a.style.transition = "all 0.5s";
        a.scrollLeft -= 200;
    }

    public getAllRoles() {
        this.feedbackSlider = true
    }

    updateBreadcrumb(): void {
        const breadcrumbs = [
            {
                label: 'Interview',
                url: ''
            },
        ];
        this.breadcrumbService.updateBreadcrumb(breadcrumbs);
    }

    getFeedbackFormName() {
        this.eventServices.getParticularStage(this.eventId, this.stageId).subscribe(res => {
            if (res.data?.scheduleInterview) {
                this.formName = res.data.scheduleInterview.interviewType;
            }
        })
    }

    public initiateCall() {
        this.eventServices.getJitsiToken(this.c_userName, this.roomName, false).subscribe(response => {
            const jitsi_div: any = document.getElementById('jaas-container');
            jitsi_div.style.display = 'block';
            this.interviewStarted = true;
            this.jitsiAPI = new JitsiMeetExternalAPI("8x8.vc", {
                roomName: response.roomName,
                parentNode: document.querySelector('#jaas-container'),
                configOverwrite: {
                    prejoinPageEnabled: false,
                    enableClosePage: true,
                    startWithAudioMuted: false,
                    startWithVideoMuted: false,
                    hideParticipantsStats: true,
                    hideConferenceSubject: true,
                    disableInviteFunctions: true,
                    disableLocalVideoFlip: true,
                    toolbarButtons: ['microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
                        'fodeviceselection', 'hangup', 'recording', 'etherpad', 'sharedvideo', 'settings',
                        'videoquality', 'filmstrip', 'security', 'tileview']
                },
                interfaceConfigOverwrite: {
                    VIDEO_QUALITY_LABEL_DISABLED: true,
                    FILM_STRIP_MAX_HEIGHT: 0
                },
                // userInfo: {
                //     displayName: this.c_userName
                // },

                jwt: response.jwt_token
            });

            const screenStopPayload: any = {
                message_type: "call_Initiated",
                eventId: this.eventId,
                user_id: this.userId,
                staff_name: this.userId,
            }
            this.eventServices.sendMessageWS(screenStopPayload).subscribe(response => {
            });
            setTimeout(() => {
                this.jitsiAPI.executeCommand('startRecording', {
                    mode: 'file'
                });
            }, 5000);

            this.jitsiAPI.addListener('videoConferenceStart', () => {
                this.meetingStatus = 'InMeeting';
                this.adminReload = false;
            });

            this.jitsiAPI.addListener('participantLeft', async () => {
                this.jitsiAPI.stopRecording('file');
                // await this.jitsiAPI.executeCommand('toggleLobby', true);
                this.meetingStatus = 'meetingEnd';
                this.adminReload = false;
                this.updateInterviewStatus('meetingEnd');
            });

            this.jitsiAPI.addListener('participantJoined', async () => {
                this.meetingStatus = 'InMeeting';
                this.adminReload = false;
                this.makeSound();
            });

            this.jitsiAPI.addListener('videoConferenceLeft', async () => {
                this.meetingStatus = 'meetingEnd';
                this.adminReload = false;
                this.jitsiAPI.stopRecording('file');
                const screenStopPayload: any = {
                    message_type: "call_finished",
                    eventId: this.eventId,
                    user_id: this.userId,
                    staff_name: this.userId,
                    schoolId: this.schoolId
                }
                this.eventServices.sendMessageWS(screenStopPayload).subscribe(response => {
                });
                this.interviewStarted = false;
                this.jitsiAPI.dispose();
                this.jitsiAPI = undefined;
                jitsi_div.style.display = 'none';
            })
        });
    }

    public socketHandler() {
        const sub = this.socketService.messageRecieved.subscribe(async (message: any) => {
            if (message && message.action) {
                switch (message.action) {
                    case "candidate_left": {
                    }
                }
            }
        })
    }

    public makeSound() {
        let audio = new Audio('../../../assets/audio/participant-joined-3.wav');
        audio.play();
    };

    getResultAnalysis(sourceData: any) {
        let contestId = sourceData.slice(0, 36);
        let testId = sourceData.slice(36);
        let payload = {
            source: 'result-analysis',
        };
        this.isLoading = true;
        this.globalService
            .getResultAnalysis(payload, contestId, testId, this.candidateEmail)
            .subscribe((resp: any) => {
                this.isLoading = false;
                let url = resp.data.data;
                if (resp.data.data && resp.data.data.includes('https')) {
                    let target_host = 'examly.io';
                    if (this.ENV === 'dev') {
                        target_host = 'exam.ly';
                    } else if (this.ENV === 'acc') {
                        target_host = 'examly.net';
                    }
                    url = url.replace('examly.io', target_host);
                    window.open(url, '_blank');
                } else {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Analysis not found',
                        detail: 'Candidate yet to start the test !',
                    });
                }
            });
    }

    public updateInterviewStatus(status: string) {
        return new Promise((resolve) => {
            let payload = {
                id: this.userEventData.id,
                userId: this.userId,
                eventId: this.eventId,
                schoolId: this.schoolId,
                stageId: Number(this.stageId),
                status: status
            };

            this.eventServices.updateInterviewStatus(payload).subscribe((response) => {
                //console.log("Response -- ", response);
            });
        })
    };

    isNumber(value: any) {
        return typeof value == 'number' ? true : false;
    }
}