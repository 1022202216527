<div class="accessManagament-card">
    <div>
        <div class="card-header">
            <p>Access Management</p>
        </div>
        <div class="members-container">
            <p class="members-heading">Recruiter</p>
            <div class="selectedMembers">
                <div *ngFor="let recruiter of selectedRecruiters">
                    <div [ngStyle]="{'opacity': (recruiter.tempAdd ? '0.5' : !userPrivileges.editAccessManagement ? '0.8' : '1'), 'pointerEvents': !userPrivileges.editAccessManagement ? 'none' : 'auto'}"
                        class="{{ recruiter.name ? 'selectedMember-container' : 'hidden'}}">
                        <div class="selectedMember-badge">{{recruiter.badge}}</div>
                        <p class="selectedMember-name">{{recruiter.name}}</p>
                        <p class="selectedMember-email">{{recruiter.email}}</p>
                        <p-progressSpinner *ngIf="recruiter.tempAdd" [style]="{height: '20px', width: '20px'}"
                            strokeWidth="7" animationDuration="0.5s">
                        </p-progressSpinner>
                        <em *ngIf="!recruiter.tempAdd" class="pi pi-times selectedMember-delete"
                            (click)="removeRecruiter(recruiter); recruiter.tempAdd = true"></em>
                    </div>
                </div>
                <p-inplace *ngIf="!isLoadingRecruiter" #ip [closable]="true" [style]="{'min-height':'33px'}"  [disabled]="!userPrivileges.editAccessManagement" (onDeactivate)="searchRecruiter(recruiterSearchTerm = '')">
                    <ng-template pTemplate="display">
                        <em class="pi pi-plus"></em>
                        <span>Add Member</span>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <input #recruiter type="text" placeholder="Search" pInputText
                            (click)="recruiterSearchTerm.length === 0 ? recOp.show($event) : searchRecruiter(recruiterSearchTerm); recOp.show($event)"
                            [(ngModel)]="recruiterSearchTerm" (ngModelChange)="searchRecruiter($event)">
                    </ng-template>
                    <p-overlayPanel #recOp>
                        <ng-template pTemplate>
                            <div class="overlay-container" *ngIf="_availableRecruiters.length; else noMembers">
                                <div *ngFor="let member of _availableRecruiters" class="recruiter-overlay">
                                    <p [ngClass]="member.name == 'No Records' ? 'no-record' : ''"
                                        class="overlay-member" (click)="addRecruiter(member, $event, recOp, ip)">
                                        {{ member.name }}
                                    </p>
                                </div>
                            </div>
                            <ng-template pTemplate #noMembers>
                                <p class="no-members">No Recruiters Available</p>
                            </ng-template>
                        </ng-template>
                    </p-overlayPanel>

                </p-inplace>
            </div>
        </div>
        <div class="members-container">
            <p class="members-heading">Interviewer</p>
            <div class="selectedMembers">
                <div *ngFor="let interviewer of selectedInterviewers">
                    <div [ngStyle]="{'opacity': (interviewer.tempAdd ? '0.5' : !userPrivileges.editAccessManagement ? '0.8' : '1'), 'pointerEvents': !userPrivileges.editAccessManagement ? 'none' : 'auto'}"
                        class="{{ interviewer.name ? 'selectedMember-container' : 'hidden'}}">
                        <div class="selectedMember-badge">{{interviewer.badge}}</div>
                        <p class="selectedMember-name">{{interviewer.name}}</p>
                        <p class="selectedMember-email">{{interviewer.email}}</p>
                        <p-progressSpinner *ngIf="interviewer.tempAdd" [style]="{height: '20px', width: '20px'}"
                            strokeWidth="7" animationDuration="0.5s">
                        </p-progressSpinner>
                        <em *ngIf="!interviewer.tempAdd" class="pi pi-times selectedMember-delete"
                            (click)="removeInterviewer(interviewer); interviewer.tempAdd = true"></em>
                    </div>
                    <div>
                    </div>
                </div>
                <p-inplace *ngIf="!isLoadingInterviewer" #ip [closable]="true" [style]="{'min-height':'33px'}" [disabled]="!userPrivileges.editAccessManagement" (onDeactivate)="searchInterviewer(interviewerSearchTerm = '')">
                    <ng-template pTemplate="display">
                        <em class="pi pi-plus"></em>
                        <span>Add Member</span>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <input #interviewer type="text" placeholder="Search" pInputText
                            (click)="interviewerSearchTerm.length === 0 ? intop.show($event) : searchInterviewer(interviewerSearchTerm); intop.show($event)"
                            [(ngModel)]="interviewerSearchTerm" (ngModelChange)="searchInterviewer($event)">
                    </ng-template>
                    <p-overlayPanel #intop>
                        <ng-template pTemplate>
                            <div class="overlay-container" *ngIf="_availableInterviewers.length; else noMembers">
                                <div *ngFor="let member of _availableInterviewers">
                                    <p [ngClass]="member.name == 'No Records' ? 'no-record' : ''"
                                        class="overlay-member" (click)="addInterviewer(member, $event, intop, ip)">
                                        {{ member.name }}
                                    </p>
                                </div>
                            </div>
                            <ng-template pTemplate #noMembers>
                                <p class="no-members">No Interviewers Available</p>
                            </ng-template>
                        </ng-template>
                    </p-overlayPanel>
                </p-inplace>
            </div>
        </div>
        <div class="members-container">
            <p class="members-heading">Evaluator</p>
            <div class="selectedMembers">
                <div [ngStyle]="{'opacity': (manager.tempAdd ? '0.5' : !userPrivileges.editAccessManagement ? '0.8' : '1'), 'pointerEvents': !userPrivileges.editAccessManagement ? 'none' : 'auto'}"
                    class="{{ manager.name ? 'selectedMember-container' : 'hidden'}}"
                    *ngFor="let manager of selectedHiringManagers">
                    <div class="selectedMember-badge">{{manager.badge}}</div>
                    <p class="selectedMember-name">{{manager.name}}</p>
                    <p class="selectedMember-email">{{manager.email}}</p>
                    <p-progressSpinner *ngIf="manager.tempAdd" [style]="{height: '20px', width: '20px'}" strokeWidth="7"
                        animationDuration="0.5s">
                    </p-progressSpinner>
                    <em *ngIf="!manager.tempAdd" class="pi pi-times selectedMember-delete"
                        (click)="removeManager(manager); manager.tempAdd = true"></em>
                </div>
                <p-inplace *ngIf="!isLoadingManager" #managerIp [closable]="true" [style]="{'min-height':'33px'}" [disabled]="!userPrivileges.editAccessManagement" (onDeactivate)="searchManager(hiringManagerSearchTerm = '')">
                    <ng-template pTemplate="display">
                        <em class="pi pi-plus"></em>
                        <span>Add Member</span>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <input #hiringManager type="text" placeholder="Search" pInputText
                            (click)="hiringManagerSearchTerm.length === 0 ? managerOp.show($event) : searchManager(hiringManagerSearchTerm); managerOp.show($event) "
                            [(ngModel)]="hiringManagerSearchTerm" (ngModelChange)="searchManager($event)">
                    </ng-template>
                    <p-overlayPanel #managerOp>
                        <ng-template pTemplate >
                            <div class="overlay-container" *ngIf="_availableHiringManagers.length; else noMembers">
                                <div *ngFor="let member of _availableHiringManagers">
                                    <p [ngClass]="member.name == 'No Records' ? 'no-record' : ''"
                                        class="overlay-member" (click)="addManager(member, $event, managerOp, managerIp)">
                                        {{ member.name }}
                                    </p>
                                </div>
                            </div>
                            <ng-template pTemplate #noMembers>
                                <p class="no-members">No Evaluators Available</p>
                            </ng-template>
                        </ng-template>
                    </p-overlayPanel>
                </p-inplace>
            </div>
        </div>
        <div class="members-container">
            <p class="members-heading">Panel</p>
            <div class="added-panels">
                <div class="{{ panels.length ? 'added-panels-container selectedMember-container' : 'hidden'}}"
                    *ngFor="let panel of panels; let i = index">
                    <p class="added-panel">Panel {{i+1}} </p>
                    <div *ngIf="!panel.isUpdate" class="panel-members">
                        <p [style]="{color: '#000000'}">{{panel.allPanelMembersString}} </p>
                    </div>
                    <div class="isupdate-container" *ngIf="panel.isUpdate">
                        <div (click)="panelOp.toggle($event)" class="add-members add-member-label">
                            <em class="pi pi-plus"></em>
                            <span>Add Member</span>
                        </div>
                        <p-overlayPanel #panelOp>
                            <ng-template pTemplate>
                                <div *ngFor="let member of allMembers" class="add-panel-checkbox">
                                    <p-checkbox (change)="clickedMember($event, member, panel)"
                                        [(ngModel)]="selectedPanelMembers" name="group2" value="category"
                                        [value]="member.id" [inputId]="member.id">
                                    </p-checkbox>
                                    <label [for]="member.id">{{member.name}}</label>
                                </div>
                            </ng-template>
                        </p-overlayPanel>
                    </div>
                    <div *ngIf="!panel.isUpdate && !panel.tempAdd" class="notupdate-container">
                        <em class="pi pi-times selectedMember-delete" (click)="hidePanel($event, panel, i)"></em>
                        <em class="pi pi-pencil selectedMember-delete" (click)="updatePanel(i)"></em>
                    </div>
                    <div *ngIf="panel.isUpdate && !panel.tempAdd && !panel.isUpdateActive">
                        <div class="flex-center">
                            <em (click)="saveUpdatePanel(('Panel '+(i+1)), selectedPanelMembers);panel.isUpdateActive = true;showPanelAdd = false"
                                class="pi pi-check addActionIcon addIcon-plus_custom"></em>
                            <em (click)="setSelectedMember(panel, true)"
                                class="pi pi-times deleteActionIcon removeIcon-minus_custom"></em>
                        </div>
                    </div>
                    <div *ngIf="panel.isUpdateActive || panel.tempAdd">
                        <p-progressSpinner class="{{panel.isUpdateActive ? 'add-spinner' : 'block-spinner'}}"
                            [style]="{height: '20px', width: '20px'}" strokeWidth="7" animationDuration="0.5s">
                        </p-progressSpinner>
                    </div>
                </div>
                <div *ngIf="showPanelAdd && !addNewMember">
                    <p-inplace #panelIp  [disabled]="!userPrivileges.editAccessManagement">
                        <ng-template pTemplate="display">
                            <em class="pi pi-plus"></em>
                            <span>Add Panel</span>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="panel-lists">
                                <div [ngClass]="panels.length > 0 ? 'new-member' : ''" class="add-panel-container"
                                    (click)="this.selectedPanelMembers = []; panelOp.toggle($event)">
                                    <p class="add-panel">Panel {{panels.length + 1}}</p>
                                    <div class="add-members add-member-label">
                                        <em class="pi pi-plus"></em>
                                        <span>Add Member</span>
                                    </div>
                                    <p-overlayPanel #panelOp>
                                        <ng-template pTemplate>
                                            <div *ngFor="let member of allMembers" class="add-panel-checkbox">
                                                <p-checkbox [(ngModel)]="selectedPanelMembers" name="group2"
                                                    value="category" [value]="member.id" [inputId]="member.id">
                                                </p-checkbox>
                                                <label [for]="member.id">{{member.name}}</label>
                                            </div>
                                        </ng-template>
                                    </p-overlayPanel>
                                </div>
                                <div [ngStyle]="{'margin-top': panels.length > 0 ? '-4.2px' : '0px' }"
                                    class="flex-prop">
                                    <em *ngIf="selectedPanelMembers.length" (click)="panelSelect(panelIp)"
                                        class="pi pi-check addActionIcon addIcon-plus"></em>
                                    <em (click)="panelClose(panelIp)"
                                        class="pi pi-times deleteActionIcon removeIcon-minus"></em>
                                </div>
                            </div>
                        </ng-template>
                    </p-inplace>
                </div>
                <div *ngIf="addNewMember">
                    <p-progressSpinner class="add-spinner" [style]="{height: '20px', width: '20px'}" strokeWidth="7"
                        animationDuration="0.5s">
                    </p-progressSpinner>
                </div>
            </div>
        </div>
        <!-- <div class="members-container">
            <p class="members-heading">TPO</p>
            <div class="selectedMembers">
                <div [ngStyle]="{'opacity': (tpo.tempAdd ? '0.5' : !userPrivileges.editAccessManagement ? '0.8' : '1'), 'pointerEvents': !userPrivileges.editAccessManagement ? 'none' : 'auto'}"
                    class="{{ tpo.name ? 'selectedMember-container' : 'hidden'}}" *ngFor="let tpo of selectedTpo">
                    <div class="selectedMember-badge">{{tpo.badge}}</div>
                    <p class="selectedMember-name">{{tpo.name}}</p>
                    <p class="selectedMember-email">{{tpo.email}}</p>
                    <p-progressSpinner *ngIf="tpo.tempAdd" [style]="{height: '20px', width: '20px'}" strokeWidth="7"
                        animationDuration="0.5s">
                    </p-progressSpinner>
                    <em *ngIf="!tpo.tempAdd" class="pi pi-times selectedMember-delete"
                        (click)="removeTpo(tpo); tpo.tempAdd = true"></em>
                </div>
                <p-inplace *ngIf="!isLoadingTpo" #tpoIp [closable]="true" [style]="{'min-height':'33px'}" [disabled]="!userPrivileges.editAccessManagement">
                    <ng-template pTemplate="display">
                        <em class="pi pi-plus"></em>
                        <span>Add Member</span>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <input #tpo type="text" placeholder="Search" pInputText
                            (click)="tpoSearchTerm.length === 0 ? tpoOp.show($event) : null "
                            (ngModelChange)="searchTpo($event)">
                    </ng-template>
                    <p-overlayPanel #tpoOp>
                        <ng-template pTemplate>
                            <div class="overlay-container" [style]="{overflowY: 'scroll',height: '100px'}">
                                <div *ngFor="let tpo of _availableTpo" class="tpo-overlay">
                                    <p [ngClass]="tpo.name == 'No TPO Available' ? 'no-record' : ''" class="overlay-member"
                                        (click)="addTpo(tpo, $event, tpoOp, tpoIp)">
                                        {{ tpo.name }}
                                    </p>
                                </div>    
                            </div>
                        </ng-template>
                    </p-overlayPanel>
                </p-inplace>
            </div>
        </div> -->
    </div>
</div>