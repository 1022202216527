import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { MessageService } from 'primeng/api';
import * as locationsList from '../../../assets/Json/locationsList.json'
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-primary-account',
  templateUrl: './primary-account.component.html',
  styleUrls: ['./primary-account.component.css'],
  providers:[MessageService]
})
export class PrimaryAccountComponent implements OnInit {

  userName: any;
  addTemplateSidebar: boolean = false;
  userInfo: any = [];
  branchData: any = [];
  currentEmailData: any = [];
  newEmailData: any = [];
  userDetails: any = [];
  showPlaceholder: boolean = false;
  _templateTitle: string = '';
  saveBtnLoading: boolean = false;
  showOtpField: boolean = false;
  verifiedMail: boolean = false;
  isLoading: boolean = true;
  emailForm: FormGroup = new FormGroup({
    oldEmail: new FormControl('', Validators.email),
    newEmail: new FormControl(),
    Otp: new FormControl(),
    emailChange: new FormControl()
  })

  passwordForm: FormGroup = new FormGroup({
    currentPassword: new FormControl(),
    newPassword: new FormControl(),
    confirmPassword: new FormControl()
  })

  accountDetails : FormGroup = new FormGroup({
    email: new FormControl(),
    password: new FormControl(),
    mobile: new FormControl('',Validators.pattern('^[0-9]{10}$')),
    primaryLocation: new FormControl(),
  })

  sidebarAction: string = '';
  sideActionIcon: string = '';
  otp: string = '';
  emailEdit: string = '';
  passwordEdit: string = '';

  verifiedCurrentEmail: boolean = false;
  currentPassShow: boolean = false;
  newPassShow: boolean = false;
  confirmPassShow: boolean = false;
  primaryLocation: string = '';
  primaryLocationList: any = (locationsList as any).default;

  constructor(private settings: SettingsService, private messageService: MessageService,private loginService: LoginService, private settingService: SettingsService) { }

  ngOnInit(): void {
    this.userName = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails') || '') : '';
    this.getAccountData();
  }

  getAccountData(){
    this.isLoading = true;
    const userDetails = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails') || '')
      : '';
    this.loginService.isLoggedIn(userDetails.email).subscribe(
      (res: any) => {
        this.userDetails = res.data ? res.data : [];
        this.userInfo = [
          { field: "Email/Username", value: this.userDetails.name },
          { field: "Password", value: '**********' ?? '-' },
          { field: "Phone/Mobile", value: this.userDetails.mobile_number ?? '-' },
        ]
        this.isLoading = false;
      });
      return true;
  }

  openAddTemplate(template: string, icon: string) {
    this.sidebarAction = template;
    this.accountDetails.get('email')?.disable();
    this.accountDetails.get('password')?.disable();
    this.sideActionIcon = icon;
    this.addTemplateSidebar = true;
    this.emailEdit = this.userInfo[0]?.value;
    this.passwordEdit = this.userInfo[1]?.value;
    this.accountDetails.controls['mobile'].setValue(this.userDetails.mobile_number);
    this.primaryLocation = this.userInfo[3]?.value;
  }


  saveTemplate = () => {
    this.saveBtnLoading = true;
    let payload = {
      schoolId: localStorage.getItem('schoolData')? JSON.parse(localStorage.getItem('schoolData') || '').school_id: '',
      userId: localStorage.getItem('userDetails')? JSON.parse(localStorage.getItem('userDetails') || '').id: '',
      mobile_number: this.accountDetails.controls['mobile'].value,
      email: this.userName.email
  };
    if(!this.accountDetails.controls['mobile'].errors){
      this.settingService.updateAccount(payload).subscribe((res: any) => {
        if(res.status == 200){
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Saved Successfully', })
          setTimeout(()=>{                          
            this.getAccountData();
            this.addTemplateSidebar = false;
          }, 1000)
        }
        else{
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something went Wrong!', })
        }
        this.saveBtnLoading = false;

      });
    }
    else
      {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Invalid Mobile Number!', })
        this.saveBtnLoading = false;
      }
  }

  onOtpChange(e:any)
  {
    this.otp = e
  }

  onConfigChange(){
    this.verifiedMail = false;
    this.showOtpField = false;
    this.otp = '';
  }

  showPassword(selector: any) {
    let x:any = document.getElementsByClassName(selector)[0];
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  verifyEmail(){
    if(!this.emailForm.controls['oldEmail'].errors){
      if(!this.verifiedCurrentEmail){
        this.verifiedMail = true;
        let oldEmailId = {
          'oldMail' : this.userName.email,
        };
        this.settings.currentEmail(this.userName.id, oldEmailId).subscribe(res => {
          if(res.status == 200){
            this.currentEmailData = res.data;
            this.showOtpField = true;
          } else if (res.status === 500) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error });
          }
        })
      }else if(this.verifiedCurrentEmail){
        this.verifiedMail = true;
        let newEmailId = {
          'id' : this.currentEmailData.id,
          'newMail' : this.emailForm.controls['newEmail'].value
        };
        this.settings.newEmail(this.userName.id, newEmailId).subscribe(res => {
            if(res.status == 200){
            this.showOtpField = true;
            this.newEmailData = res;
            }else if (res.status === 500) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error });
          }
        })
      }

    }
  }

  verifyOtp(){
    let currPayload = {
      id: this.currentEmailData.id,
      OTP: this.otp
    }

    let newPayload = {
      id: this.newEmailData.id,
      OTP: this.otp
    }
    this.onConfigChange();
    if(this.verifiedCurrentEmail){
      this.settings.newEmailVerify(newPayload, this.userName.id).subscribe(res => {
          if(res.status == 200){
          this.onOtpChange(" ");
          this.emailForm.reset();
          this.verifiedCurrentEmail = false;
          this.onConfigChange();
          this.addTemplateSidebar = false;
          }else if (res.status === 500) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error });
        }
      })
    }else {
      this.settings.currentEmailVerify(currPayload).subscribe(res => {
      if(res.status == 200){
          this.emailForm.controls['oldEmail'].disable();
          this.verifiedCurrentEmail = true;
          this.onConfigChange();
      }else if (res.status === 500) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error });
        }
      })
    }

  }
}
