import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.css']
})

export class BranchesComponent implements OnInit {

  addTemplateSidebar: boolean = false;
  userInfo: any = [];
  branchData: any = [];
  showPlaceholder: boolean = false;
  _templateTitle: string = '';
  saveBtnLoading: boolean = false;
  branchName: string = '';
  branchList: any;
  edit: boolean = false;
  clicked: boolean = false;
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,24}$/;
  // selectedBranch: string = '';
  editIndex: any = -1;
  branchDetails: FormGroup = new FormGroup({
    branchName: new FormControl('', Validators.required),
    branchContact: new FormControl(),
    branchEmail: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    branchAddress: new FormControl('', Validators.required),
    address2: new FormControl(),
    branchArea: new FormControl(),
    branchCity: new FormControl(),
    branchState: new FormControl(),
    branchZip: new FormControl()
  })

  constructor(private settings: SettingsService, private messageService: MessageService) { }

  ngOnInit(): void {

    this.settings.getBranchData().subscribe((res: any) => {
      if (res.data) {
        this.branchData.push(res.data)
      }
      console.log(this.branchData)
    })
  }

  editBranch(id: number) {
    this.edit = true;
    this.editIndex = id;
    this.addTemplateSidebar = true
    this.branchDetails.patchValue(this.branchData[id]);
    console.log(this.branchDetails)
    const schoolDetails: any = JSON.parse(localStorage.getItem('schoolDetails') as string);
    const url = schoolDetails[0].id;
    const payload = this.branchDetails.value;
    this.branchName = payload.branchName;
    console.log(payload, 'Hello')
    console.log(this.branchData, 'ello')
    this.settings.updateBranchData(url, payload).subscribe({})
  }

  deleteBranch(id: number) {
    if (this.branchData.length > -1) {
      // this.branchData.id = 0;
      if (id !== -1) {
        this.branchData.splice(id, 1);
        console.log('Success')
      }
    }
    console.log(this.branchData);
  }

  openAddTemplate() {
    this.addTemplateSidebar = true;
  }

  cancel = () => {
    this.branchDetails.reset();
    this.addTemplateSidebar = false;
  }

  saveTemplate = (edit?: any) => {
    this.clicked = true;
    if (this.branchDetails.valid) {
      if (this.edit) {
        this.branchData[this.editIndex] = this.branchDetails.value;
        // this.editIndex = -1;
      } else {
        this.branchData.push(this.branchDetails.value);
      }
      this.addTemplateSidebar = false
      this.clicked = false;
      this.branchDetails.reset();
    }
    else{
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Please Fill the Required Fields', })
      this.saveBtnLoading = false;
    }
    console.log(this.branchData);
  }

}


